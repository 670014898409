import { nanoquery } from '@nanostores/query'
import { computed } from 'nanostores'
import * as LZ from 'lz-string'
import { firestore, auth, FS, authPromise } from '../service/firebase.ts'
import { $router } from './router.ts'

export type Chapter = {
  id: string
  title: string
  content: any
  wordCount: number
  summary?: string
  session?: number
}

export const session = Date.now()

type ChapterPayload<Keys extends keyof Chapter = keyof Chapter> = Pick<
  Chapter,
  Keys
> & { book: string }

function transformChapter(doc: FS.DocumentSnapshot) {
  const content = {
    id: doc.id,
    ...doc.data()
  } as Chapter
  if (typeof content.content === 'string') {
    content.content = JSON.parse(
      LZ.decompressFromEncodedURIComponent(content.content)
    )
  }
  return content
}

const chapters = nanoquery({
  async fetcher(book) {
    const data = await FS.getDocs(
      FS.collection(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/chapters`
      )
    )

    await new Promise((resolve) => setTimeout(resolve, 1000))

    return data.docs.map(transformChapter)
  }
})

export const $book = computed($router, (page) =>
  page?.route === 'books' ? null : page?.params.book ?? null
)
export const $chapters = chapters[0]<Chapter[]>([$book])
export const createChapter = chapters[1]<
  ChapterPayload<'title'>,
  FS.DocumentReference
>(async ({ data, invalidate }) => {
  invalidate(data.book)
  return FS.addDoc(
    FS.collection(
      firestore,
      `users/${auth.currentUser?.uid}/books/${data.book}/chapters`
    ),
    { title: data.title, content: '', wordCount: 0 }
  )
})
export const updateChapter = chapters[1]<Partial<ChapterPayload>>(
  async ({ data: { book, id, ...data } }) => {
    if (data.content) {
      data.content = LZ.compressToEncodedURIComponent(
        JSON.stringify(data.content)
      )
    }
    data.session = session
    return FS.updateDoc(
      FS.doc(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/chapters/${id}`
      ),
      data
    )
  }
)
export const optimisticChapterUpdate = (data: ChapterPayload) => {
  const docs = $chapters.get().data || []
  const index = docs.findIndex((doc: any) => doc.id === data.id)
  docs[index] = { ...data, session }
  chapters[2].mutateCache(data.book, [...docs])
}

authPromise.then(() => {
  const bookSubscriptions: Record<string, FS.Unsubscribe> = {}
  $book.subscribe((book) => {
    if (book) {
      bookSubscriptions[book]?.()

      bookSubscriptions[book] = FS.onSnapshot(
        FS.collection(
          firestore,
          `users/${auth.currentUser?.uid}/books/${book}/chapters`
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(transformChapter)
          chapters[2].mutateCache(book, docs)
        }
      )
    }
  })
})
