import { nanoquery } from '@nanostores/query'
import { firestore, auth, FS } from '../service/firebase.ts'
import { computed } from 'nanostores'
import { $router } from './router.ts'

export type Gender = 'female' | 'male' | 'non-binary'
export type EyeColor =
  | 'amber'
  | 'blue'
  | 'green'
  | 'brown'
  | 'hazel'
  | 'gray'
  | 'black'
export type HairColor =
  | 'red'
  | 'copper'
  | 'blond'
  | 'caramel'
  | 'white'
  | 'gray'
  | 'dark'
  | 'brown'
  | 'black'
export type SkinTone =
  | 'pale'
  | 'fair'
  | 'tan'
  | 'honey'
  | 'chestnut'
  | 'dark'
  | 'ebony'

export const EyeColors: Record<EyeColor, string> = {
  amber: '#cc9240',
  blue: '#406ccc',
  green: '#649130',
  brown: '#865409',
  hazel: '#c2c067',
  gray: '#a4a099',
  black: '#3d3730'
}
export const HairColors: Record<HairColor, string> = {
  red: '#c73d30',
  copper: '#c06c52',
  blond: '#e8c46b',
  caramel: '#c79863',
  brown: '#98765d',
  dark: '#5e3c2a',
  black: '#494343',
  gray: '#bdbab2',
  white: '#f1f1f1'
}
export const SkinTones: Record<SkinTone, string> = {
  pale: '#fff0e4',
  fair: '#eed0b8',
  tan: '#e7bc91',
  honey: '#cb9662',
  chestnut: '#885633',
  dark: '#623a17',
  ebony: '#301e10'
}

export type Character = {
  id: string
  name: string
  gender: Gender
  eyes: EyeColor
  hair: HairColor
  skin: SkinTone
  traits: string[]
  description: string
  backstory: string
}

type CharacterPayload<Keys extends keyof Character = keyof Character> = Pick<
  Character,
  Keys
> & { book: string }

const characters = nanoquery({
  async fetcher(book) {
    const data = await FS.getDocs(
      FS.collection(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/characters`
      )
    )
    return data.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
  }
})

export const $book = computed(
  $router,
  (page) => (page?.params as any)?.book || null
)
export const $characters = characters[0]<Character[]>([$book])
export const createCharacter = characters[1]<
  CharacterPayload<'name'>,
  FS.DocumentReference
>(async ({ data, invalidate }) => {
  invalidate(data.book)
  const doc = FS.doc(
    firestore,
    'users',
    auth.currentUser!.uid,
    'books',
    data.book,
    'characters',
    `${Date.now()}`
  )
  await FS.setDoc(doc, {
    name: data.name,
    gender: 'non-binary',
    eyes: 'brown',
    hair: 'brown',
    traits: [],
    description: '',
    backstory: ''
  })
  return doc
})
export const updateCharacter = characters[1]<CharacterPayload>(
  async ({ data: { book, id, ...data }, invalidate }) => {
    invalidate(book)
    return FS.updateDoc(
      FS.doc(
        firestore,
        'users',
        auth.currentUser!.uid,
        'books',
        book,
        'characters',
        id
      ),
      data
    )
  }
)
export const optimisticCharacterUpdate = (data: CharacterPayload) => {
  const docs = $characters.get().data || []
  const index = docs.findIndex((doc: any) => doc.id === data.id)
  docs[index] = { ...data }
  characters[2].mutateCache(data.book, [...docs])
}
