import { useEffect } from 'react'
import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { extract } from '../utils.ts'

import { TopBar } from './top-bar.tsx'
import { PanelLeft } from './panel-left.tsx'
import { PanelRight } from './panel-right.tsx'
import { Books } from './books.tsx'
import { BookPreview } from './book-preview.tsx'
import { Chapter } from './chapter.tsx'
import { Characters } from './characters.tsx'
import { Places } from './places.tsx'
import { Assistant } from './assistant.tsx'
import { useStore } from '@nanostores/react'
import { $recording } from '../state/chat.ts'

const TalkingIndicator = () => (
  <div className="container palette-1">
    <div className="blobs">
      <svg viewBox="0 0 1200 1200">
        <g className="blob blob-1">
          <path />
        </g>
        <g className="blob blob-2">
          <path />
        </g>
        <g className="blob blob-3">
          <path />
        </g>
        <g className="blob blob-4">
          <path />
        </g>
        <g className="blob blob-1 alt">
          <path />
        </g>
        <g className="blob blob-2 alt">
          <path />
        </g>
        <g className="blob blob-3 alt">
          <path />
        </g>
        <g className="blob blob-4 alt">
          <path />
        </g>
      </svg>
    </div>
  </div>
)

export const Layout = Modular.Component('Layout')
  .with(
    Modular.lifecycle(() => {
      const theme = UI.useComputedColorScheme('dark')

      useEffect(() => {
        document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute(
            'content',
            theme === 'dark' ? '#000000FF' : '#FFFFFFFF'
          )
        document
          .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
          ?.setAttribute('content', theme === 'dark' ? 'black' : 'default')
      }, [theme])

      return { recording: useStore($recording) }
    })
  )
  .with(
    Modular.render(({ view, screen, lifecycle }) => {
      const Wrapper = screen.isDesktop ? UI.Group : UI.Stack
      const panelProps = {
        pos: screen.position,
        w: screen.width,
        top: screen.isDesktop ? 48 : 40,
        bg: 'var(--color-columns)',
        flex: 1,
        miw: 240,
        mih: screen.isDesktop ? 'calc(100vh - 48px)' : undefined,
        radius: 0,
        p: view.qwill ? 0 : 16,
        py: screen.isDesktop ? 16 : 0,
        style: {
          zIndex: screen.isDesktop ? undefined : 100,
          boxShadow:
            screen.isDesktop || !view.expanded
              ? ''
              : '0 4px 6px rgba(0,0,0,0.1)'
        }
      }

      const topBarFragment = (
        <UI.Stack
          pos="sticky"
          top={0}
          align="center"
          justify="center"
          gap={0}
          h={48}
          bg="var(--color-background)"
          style={{
            zIndex: 100,
            borderBottom:
              screen.isDesktop || screen.scrolled || view.qwill
                ? '1px solid var(--color-border)'
                : '',
            boxShadow:
              screen.isDesktop || screen.scrolled || view.qwill
                ? '0 0 4px rgba(0,0,0,0.1)'
                : ''
          }}
        >
          <UI.Group w="100%" p={16} justify="center" wrap="nowrap">
            <TopBar />
          </UI.Group>
        </UI.Stack>
      )

      if (view.page?.route === 'books') {
        return (
          <UI.Stack gap={0}>
            {topBarFragment}
            <UI.Stack
              key={view.page?.route + (view.focus ? 'focus' : '')}
              w="100%"
              mih={screen.isDesktop ? 'calc(100vh - 48px)' : undefined}
              style={{
                boxShadow: screen.isDesktop ? '0 0 6px rgba(0,0,0,0.1)' : '',
                zIndex: 10
              }}
              py={{ base: 32, sm: 0 }}
              bg={screen.isDesktop ? 'transparent' : 'var(--color-background)'}
              align="flex-start"
            >
              <Books />
            </UI.Stack>
          </UI.Stack>
        )
      }

      return (
        <UI.Stack gap={0}>
          {topBarFragment}

          {lifecycle.recording && <TalkingIndicator />}

          <Wrapper align="flex-start" wrap="nowrap" h="100%" gap="1px">
            <UI.Card
              {...panelProps}
              {...(view.focus ? { w: 'auto', flex: 0 } : {})}
            >
              <PanelLeft />
            </UI.Card>

            <UI.Stack
              key={view.page?.route + (view.focus ? 'focus' : '')}
              w="100%"
              mx="auto"
              maw={!view.focus || view.chapter ? '60rem' : 'auto'}
              mih={screen.isDesktop ? 'calc(100vh - 48px)' : undefined}
              style={{
                boxShadow: screen.isDesktop ? '0 0 6px rgba(0,0,0,0.1)' : '',
                zIndex: 10
              }}
              bg="var(--color-background)"
              align="flex-start"
            >
              {!view.qwill &&
                extract(view.page!.route, {
                  book: <BookPreview />,
                  chapter: <Chapter />,
                  note: <Chapter note />,
                  characters: <Characters />,
                  places: <Places />
                })}

              {!screen.isDesktop && (
                <UI.Stack px={16} display={view.qwill ? 'flex' : 'none'}>
                  <Assistant />
                </UI.Stack>
              )}
            </UI.Stack>

            {screen.isDesktop && (
              <UI.Card {...panelProps}>
                <PanelRight />
              </UI.Card>
            )}
          </Wrapper>
        </UI.Stack>
      )
    })
  )

if (import.meta.hot) import.meta.hot.accept(() => import.meta.hot?.accept())
