import { Fragment } from 'react'
import * as UI from '@mantine/core'
import { generateHTML } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { TextStyle } from '@tiptap/extension-text-style'
import Modular from './modular.ts'

const knownIds = [
  '2qLFo5itVqenvtIyiAoU',
  '9XnVAxwxSzIyTCgwpBLI',
  'z8rdtN8lfavpdqLMmyTj'
]

export const BookPreview = Modular.Component('BookPreview')
  .with(
    Modular.lifecycle(({ view }) => {
      const chapters =
        view.chapters.map((chapter) => {
          const html = chapter.content
            ? generateHTML(chapter.content as any, [StarterKit, TextStyle])
            : ''
          return {
            ...chapter,
            html: html.replaceAll(
              '<p></p>',
              '<p><br class="ProseMirror-trailingBreak"></p>'
            )
          }
        }) ?? []

      return { chapters }
    })
  )
  .with(
    Modular.render(({ view, lifecycle }) => (
      <Fragment>
        {knownIds.includes(view.book.id) && (
          <UI.Image
            maw={'min(640px, 90%)'}
            mt={{ base: 16, sm: 64 }}
            mx="auto"
            radius="md"
            style={{ border: '1px solid #3331' }}
            src={`/books/${view.book?.id}.png`}
          />
        )}
        <UI.Box maw={960} mx='auto'>
        <UI.TypographyStylesProvider className="mantine-RichTextEditor-typographyStylesProvider book-preview">
          {lifecycle.chapters.map((chapter) => (
            <Fragment key={chapter.id}>
              <h2>{chapter.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: chapter.html }} />
            </Fragment>
          ))}
        </UI.TypographyStylesProvider>
        </UI.Box>
      </Fragment>
    ))
  )
