import ReactDOM from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Layout } from './components/layout.tsx'
import { authPromise } from './service/firebase.ts'

import '@mantine/core/styles.layer.css'
import '@mantine/tiptap/styles.layer.css'

import './index.css'

authPromise.then((connected) => {
  if (connected) {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <MantineProvider
        defaultColorScheme="auto"
        theme={{
          fontFamily: 'Source Sans 3',
          primaryColor: 'indigo',
          breakpoints: {
            xs: '30em',
            sm: '90em',
            md: '90em',
            lg: '90em',
            xl: '90em'
          }
        }}
      >
        <ModalsProvider>
          <Layout />
        </ModalsProvider>
      </MantineProvider>
    )
  }
})
