import { useEffect, useMemo } from 'react'
import * as UI from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { atom } from 'nanostores'
import { useStore } from '@nanostores/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faGenderless,
  faMars,
  faPen,
  faVenus
} from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'
import {
  Character,
  EyeColors,
  HairColors,
  SkinTones
} from '../state/characters.ts'
import { extract } from '../utils.ts'
import { Eye, Face, HairFemale, HairMale } from './icons.tsx'
import { MultiSelect } from './multi-select.tsx'

const ColorPicker = Modular.Component<{
  colors: Record<string, string>
  label: string
  field: 'skin' | 'eyes' | 'hair'
  value: string
  onChange: (character: Partial<Character>) => () => void
  svg: React.ReactNode
  preview?: boolean
}>('ColorPicker').with(
  Modular.render(({ props }) => {
    const iconFragment = (
      <UI.Text
        c={props.colors[props.value]}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {props.svg}
      </UI.Text>
    )

    if (props.preview) {
      return iconFragment
    }

    return (
      <UI.InputWrapper label={props.label} mb={4}>
        <UI.HoverCard withArrow position="bottom-start">
          <UI.HoverCard.Target>
            <UI.ActionIcon mt={4} display="block" variant="subtle">
              {iconFragment}
            </UI.ActionIcon>
          </UI.HoverCard.Target>
          <UI.HoverCard.Dropdown>
            <UI.Group gap={4}>
              {Object.keys(props.colors).map((color) => (
                <UI.ColorSwatch
                  key={color}
                  radius="md"
                  title={color}
                  size={24}
                  color={props.colors[color]}
                  component={UI.UnstyledButton<'button'>}
                  onClick={props.onChange({
                    [props.field]: color
                  })}
                />
              ))}
            </UI.Group>
          </UI.HoverCard.Dropdown>
        </UI.HoverCard>
      </UI.InputWrapper>
    )
  })
)

const $expanded = atom('')

export const CharacterCard = Modular.Component<
  Character & { preview?: boolean }
>('CharacterCard')
  .with(
    Modular.lifecycle(({ props, view, act }) => {
      const [editing, { toggle: edit }] = useDisclosure()
      const expanded = useStore($expanded) === props.id
      const handleChange = useMemo(
        () => act.saveCharacter({ id: props.id, book: view.book.id }),
        [act, props.id, view.book.id]
      )

      useEffect(() => {
        return () => handleChange.commit?.()
      }, [handleChange])

      return {
        update: (value: Partial<Character>) => () =>
          handleChange({
            ...props,
            ...value
          }),
        input:
          <Key extends keyof Character>(field: Key) =>
          (
            e: Key extends 'traits'
              ? string[]
              : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) =>
            handleChange({
              ...props,
              [field]: Array.isArray(e) ? e : e.currentTarget.value
            }),
        expanded,
        toggle: () => $expanded.set(expanded ? '' : props.id),
        editing,
        edit
      }
    })
  )
  .with(
    Modular.render(({ props, lifecycle }) => {
      if (props.preview && !lifecycle.editing) {
        return (
          <UI.Card withBorder bg="var(--color-columns)" radius="md">
            <UI.Group
              align="center"
              onClick={lifecycle.toggle}
              style={{ cursor: 'pointer' }}
            >
              <UI.Text fw="bold">{props.name}</UI.Text>
              <UI.Group ml="auto">
                <ColorPicker
                  field="hair"
                  value={props.hair}
                  onChange={lifecycle.update}
                  colors={HairColors}
                  label="Hair"
                  svg={
                    props.gender === 'female' ? <HairFemale /> : <HairMale />
                  }
                  preview
                />
                <ColorPicker
                  field="eyes"
                  value={props.eyes}
                  onChange={lifecycle.update}
                  colors={EyeColors}
                  label="Eyes"
                  svg={<Eye />}
                  preview
                />
                <ColorPicker
                  field="skin"
                  value={props.skin}
                  onChange={lifecycle.update}
                  colors={SkinTones}
                  label="Skin"
                  svg={<Face />}
                  preview
                />
                <UI.ActionIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    lifecycle.edit()
                  }}
                  color="gray"
                  size="sm"
                  variant="subtle"
                >
                  <FontAwesomeIcon size="xs" icon={faPen} />
                </UI.ActionIcon>
              </UI.Group>
            </UI.Group>

            <UI.Collapse in={lifecycle.expanded}>
              <UI.Stack mt={8}>
                {props.description && (
                  <UI.Stack gap={0}>
                    <UI.Text fz={14} fw="bold">
                      Description
                    </UI.Text>
                    <UI.Text fz={14}>{props.description}</UI.Text>
                  </UI.Stack>
                )}
                {props.traits.length > 0 && (
                  <UI.Group gap={4}>
                    {props.traits.map((trait) => (
                      <UI.Pill bg="indigo.1" key={trait}>
                        {trait}
                      </UI.Pill>
                    ))}
                  </UI.Group>
                )}
                {props.backstory && (
                  <UI.Stack gap={0}>
                    <UI.Text fz={14} fw="bold">
                      Backstory
                    </UI.Text>
                    <UI.Text fz={14}>{props.backstory}</UI.Text>
                  </UI.Stack>
                )}
                {!props.description && !props.backstory && (
                  <UI.Text fs="italic" fz={14} ta="center">
                    No description or backstory yet
                  </UI.Text>
                )}
              </UI.Stack>
            </UI.Collapse>
          </UI.Card>
        )
      }

      return (
        <UI.Card withBorder bg="var(--color-columns)" radius="md">
          <UI.Stack>
            <UI.Group w="100%">
              <UI.Text fw="bold">{props.name}</UI.Text>
              {props.preview && (
                <UI.ActionIcon
                  ml="auto"
                  onClick={(e) => {
                    e.stopPropagation()
                    lifecycle.edit()
                  }}
                  color="gray"
                  size="sm"
                  variant="subtle"
                >
                  <FontAwesomeIcon size="xs" icon={faClose} />
                </UI.ActionIcon>
              )}
            </UI.Group>

            <UI.Group align="flex-end">
              <UI.TextInput
                name="name"
                label="Name"
                value={props.name}
                onChange={lifecycle.input('name')}
                flex={1}
              />
              <UI.InputWrapper label="Gender">
                <UI.ActionIcon
                  display="block"
                  size="md"
                  my={4}
                  mx="auto"
                  onClick={lifecycle.update({
                    gender: extract(props.gender, {
                      'non-binary': 'female',
                      'female': 'male',
                      'male': 'non-binary'
                    } as const)
                  })}
                  color={extract(props.gender, {
                    'non-binary': 'gray-6',
                    'female': 'grape.8',
                    'male': 'blue.7'
                  })}
                  autoContrast
                  variant="subtle"
                >
                  <FontAwesomeIcon
                    icon={extract(props.gender, {
                      'non-binary': faGenderless,
                      'female': faVenus,
                      'male': faMars
                    })}
                  />
                </UI.ActionIcon>
              </UI.InputWrapper>
              <ColorPicker
                field="hair"
                value={props.hair}
                onChange={lifecycle.update}
                colors={HairColors}
                label="Hair"
                svg={props.gender === 'female' ? <HairFemale /> : <HairMale />}
              />
              <ColorPicker
                field="eyes"
                value={props.eyes}
                onChange={lifecycle.update}
                colors={EyeColors}
                label="Eyes"
                svg={<Eye />}
              />
              <ColorPicker
                field="skin"
                value={props.skin}
                onChange={lifecycle.update}
                colors={SkinTones}
                label="Skin"
                svg={<Face />}
              />
            </UI.Group>

            <MultiSelect
              label="Traits"
              value={props.traits}
              onChange={lifecycle.input('traits')}
            />

            <UI.Textarea
              value={props.description}
              onChange={lifecycle.input('description')}
              label="Description"
              autosize
            />
            <UI.Textarea
              value={props.backstory}
              onChange={lifecycle.input('backstory')}
              label="Backstory"
              autosize
            />
          </UI.Stack>
        </UI.Card>
      )
    })
  )
