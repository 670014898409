import { atom, computed } from 'nanostores'
import { getPagePath, ParamsArg, Router } from '@nanostores/router'
import { persistentAtom } from '@nanostores/persistent'

import { $router } from './router.ts'
import { $isDesktop } from './screen.ts'
import { $books, reorderChapters, updateBook } from './books.ts'
import { $chapters, createChapter, updateChapter } from './chapters.ts'
import { $notes, createNote, Note, updateNote } from './notes.ts'
import { $characters, createCharacter } from './characters.ts'
import { $places, createPlace } from './places.ts'
import {
  $dirtySet,
  debounceChapterUpdate,
  debounceCharacterUpdate,
  debounceNoteUpdate,
  debouncePlaceUpdate
} from './debounce.ts'
import { updateChatHistory, setChatHistory } from './chat.ts'

type RouterConfig = typeof $router extends Router<infer U> ? U : never

const $expanded = atom(false)
const $qwill = atom(false)
const $focus = persistentAtom<boolean>('focus', false, {
  encode: JSON.stringify,
  decode: JSON.parse
})
export const $view = computed(
  [
    $router,
    $isDesktop,
    $books,
    $chapters,
    $notes,
    $characters,
    $places,
    $expanded,
    $qwill,
    $focus,
    $dirtySet
  ],
  (
    page,
    isDesktop,
    _books,
    _chapters,
    _notes,
    _characters,
    _places,
    expanded,
    qwill,
    focus,
    dirtySet
  ) => {
    const bookId = (page?.route !== 'books' && page?.params.book) || ''
    const books = _books.data ?? []
    const book = books.find((b) => b.id === bookId) ?? {
      id: '',
      title: ''
    }
    const chapters = (book?.chapters ?? [])
      .map((c) => _chapters.data?.find((d) => d.id === c))
      .filter((a): a is NonNullable<typeof a> => !!a)
    const allNotes = (book?.notes ?? [])
      .map((c) => _notes.data?.find((d) => d.id === c))
      .filter((a): a is NonNullable<typeof a> => !!a)
    const notes = allNotes.filter((a) => !a.draftFor)
    const characters = _characters.data ?? []
    const places = _places.data ?? []

    const chapter = chapters.find(
      (c) => page?.route === 'chapter' && c.id === page.params.chapter
    )
    const draft = _notes.loading ? { id: 'draft', content: '', title: 'Draft' } as Note :
      _notes.data?.find((a) => page?.route === 'chapter' && a.draftFor === page.params.chapter)
    const note = notes.find(
      (c) => page?.route === 'note' && c.id === page.params.note
    )
    const previousSummary = chapters.indexOf(chapter!)
      ? chapters[chapters.indexOf(chapter!) - 1]?.summary
      : 'This is the first chapter'
    const previousChapter = chapters.indexOf(chapter!) ? chapters[chapters.indexOf(chapter!) - 1]
      : null

    return {
      page,
      book,
      books,
      chapters,
      notes,
      draft,
      chapter,
      previousSummary,
      previousChapter,
      note,
      characters,
      places,
      expanded: isDesktop || expanded,
      toggleExpanded: (force?: boolean) => $expanded.set(force ?? !expanded),
      qwill: !!chapter && !isDesktop && qwill,
      toggleQwill: (force?: boolean) => $qwill.set(force ?? !qwill),
      focus: isDesktop && focus,
      toggleFocus: (force?: boolean) => $focus.set(force ?? !focus),
      getPagePath: <PageName extends keyof RouterConfig>(
        name: PageName,
        ...params: ParamsArg<RouterConfig, PageName>
      ) => getPagePath($router, name, ...params),
      unsaved: dirtySet.size > 0
    }
  }
)

export const actions = {
  saveNote: debounceNoteUpdate,
  saveChapter: debounceChapterUpdate,
  saveCharacter: debounceCharacterUpdate,
  savePlace: debouncePlaceUpdate,
  createNote,
  createChapter,
  createCharacter,
  createPlace,
  updateBook,
  updateChapter,
  updateNote,
  setChatHistory,
  updateChatHistory,
  reorderChapters
}
