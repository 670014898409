import { useEffect, useMemo } from 'react'
import * as UI from '@mantine/core'
import { atom } from 'nanostores'
import { useStore } from '@nanostores/react'
import Modular from './modular.ts'
import { Editor } from './editor.tsx'
import { session } from '../state/chapters.ts'
import { Note } from '../state/notes.ts'

const $note = atom<string>('draft')

export const NotePreview = Modular.Component('NotePreview')
  .with(
    Modular.lifecycle(({ view, act }) => {
      const note = useStore($note)
      const _note = note === 'draft' ? view.draft : view.notes.find((n) => n.id === note)
      const content = _note?.content
      const book = view.book?.id as string
      const noteTitle = _note?.title as string

      const handleChange = useMemo(
        () =>
          act.saveNote({
            book,
            id: note === 'draft' ? view.draft?.id as string : note,
            title: noteTitle,
            draftFor: _note?.draftFor
          }),
        [act, book, note, noteTitle, _note?.draftFor, view.draft?.id]
      )
      useEffect(() => handleChange.commit, [handleChange])

      useEffect(() => {
        if (!view.draft) {
          act.createNote.mutate({
            title: 'Draft',
            book: view.book.id,
            draftFor: view.chapter?.id
          })
        }
      }, [view.draft, view.book.id, view.chapter?.id, act.createNote])

      return { note, content, handleChange, session: _note?.session }
    })
  )
  .with(
    Modular.render(({ view, lifecycle }) => (
      <UI.Stack className="no-mobile">
        <UI.Select
          label="Note"
          data={
            [{ title: 'Draft', id: 'draft' }, ...view.notes].filter((a): a is Note => !!a).map((note) => ({
              value: note.id,
              label: note.title
            })) ?? []
          }
          value={lifecycle.note}
          onChange={(value) => $note.set(value ?? '')}
          clearable
        />

        {lifecycle.content !== undefined && (
          <UI.ScrollArea
            h="calc(100vh - 13rem)"
            style={{
              border: '1px solid var(--mantine-color-gray-4)',
              borderRadius: '0.25rem'
            }}
          >
            <Editor
              key={lifecycle.note === 'draft' ? view.draft?.id : lifecycle.note}
              noMargin
              forceSet
              content={lifecycle.content}
              onChange={lifecycle.handleChange}
              shouldUpdate={lifecycle.session !== session}
            />
          </UI.ScrollArea>
        )}
      </UI.Stack>
    ))
  )
