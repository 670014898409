import { createRouter } from '@nanostores/router'

export const $router = createRouter({
  books: '/',
  book: '/b/:book',
  chapter: '/b/:book/c/:chapter',
  note: '/b/:book/n/:note',
  characters: '/b/:book/characters',
  places: '/b/:book/places'
})
