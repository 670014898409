import { atom, computed } from 'nanostores'

const query = matchMedia('(min-width: 90em)')
export const $isDesktop = atom(query.matches)

query.addEventListener('change', (e) => $isDesktop.set(e.matches))

const $scrolled = atom(false)
document.querySelector('#root')?.addEventListener('scroll', () => {
  const root = document.querySelector('#root') as HTMLDivElement
  $scrolled.set(root.scrollTop > 0)
})

export const $screen = computed(
  [$isDesktop, $scrolled],
  (isDesktop, scrolled) => ({
    isDesktop,
    scrolled,
    position: isDesktop ? ('sticky' as const) : ('fixed' as const),
    width: isDesktop ? undefined : '100%',
    maxWidth: isDesktop ? 320 : undefined
  })
)
