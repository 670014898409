import { useState } from 'react'
import * as UI from '@mantine/core'

export function MultiSelect(
  props: {
    value: string[]
    onChange: (data: string[]) => void
  } & Omit<UI.InputWrapperProps, 'onChange'>
) {
  const { value, onChange, ...rest } = props
  const combobox = UI.useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })

  const [search, setSearch] = useState('')

  const handleValueAdd = () => {
    setSearch('')
    onChange([...value, search])
  }
  const handleValueRemove = (val: string) => {
    onChange(value.filter((v) => v !== val))
  }

  const values = value.map((item) => (
    <UI.Pill
      key={item}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
    >
      {item}
    </UI.Pill>
  ))

  return (
    <UI.InputWrapper {...rest}>
      <UI.PillsInput onClick={() => combobox.openDropdown()}>
        <UI.Pill.Group>
          {values}

          <UI.PillsInput.Field
            onFocus={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
            value={search}
            onChange={(event) => {
              combobox.updateSelectedOptionIndex()
              setSearch(event.currentTarget.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace' && search.length === 0) {
                event.preventDefault()
                handleValueRemove(value[value.length - 1])
              }
              if (event.key === 'Enter' && search.length !== 0) {
                event.preventDefault()
                handleValueAdd()
              }
            }}
          />
        </UI.Pill.Group>
      </UI.PillsInput>
    </UI.InputWrapper>
  )
}
