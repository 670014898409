import { nanoquery } from '@nanostores/query'
import { firestore, auth, FS, authPromise } from '../service/firebase.ts'

export type Book = {
  id: string
  title: string
  chapters?: string[]
  notes?: string[]
  fiction?: boolean
  language?: 'french' | 'english'
}

const books = nanoquery({
  async fetcher() {
    const data = await FS.getDocs(
      FS.collection(firestore, `users/${auth.currentUser?.uid}/books`)
    )

    return data.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
  }
})

export const $books = books[0]<Book[]>('list')
export const updateBook = books[1]<{
  title?: string
  id: string
  chapters?: string[]
  notes?: string[]
}>(async ({ data }) => {
  return FS.updateDoc(
    FS.doc(firestore, `users/${auth.currentUser?.uid}/books/${data.id}`),
    data
  )
})

export const reorderChapters = async (
  book: Book,
  source: number,
  dest: number
) => {
  const chapters = [...(book.chapters ?? [])]
  const chapter = chapters[source]
  chapters.splice(source, 1)
  chapters.splice(dest, 0, chapter)

  const allBooks = $books.get().data ?? []
  const bookIndex = allBooks.findIndex((b) => b.id === book.id)
  allBooks[bookIndex].chapters = chapters
  books[2].mutateCache('list', [...allBooks])

  await updateBook.mutate({
    ...book,
    chapters
  })
}

authPromise.then(() => {
  FS.onSnapshot(
    FS.collection(firestore, `users/${auth.currentUser?.uid}/books`),
    (snapshot) => {
      books[2].mutateCache(
        'list',
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
      )
    }
  )
})
