import * as UI from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'
import { PlaceCard } from './place-card.tsx'

export const Places = Modular.Component('Places').with(
  Modular.render(({ view, act }) => (
    <UI.Stack p={16} w="100%">
      <UI.Text fz={20} fw="bold">
        Places
      </UI.Text>
      <UI.Box
        w="100%"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))',
          gap: 24
        }}
      >
        {view.places.map((character) => (
          <PlaceCard key={character.id} {...character} />
        ))}
        <UI.Card withBorder radius="md" bg="var(--color-columns)">
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              const { value: name } = e.currentTarget.elements[
                'name' as any
              ] as HTMLInputElement
              e.currentTarget.reset()
              await act.createPlace.mutate({
                book: view.book.id,
                name
              })
            }}
          >
            <UI.Stack>
              <UI.Text fw="bold">New place</UI.Text>

              <UI.Group align="flex-end">
                <UI.TextInput name="name" flex={1} label="Name" />
                <UI.Button
                  type="submit"
                  rightSection={<FontAwesomeIcon icon={faPlus} />}
                >
                  Create
                </UI.Button>
              </UI.Group>
            </UI.Stack>
          </form>
        </UI.Card>
      </UI.Box>
    </UI.Stack>
  ))
)
