import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { PlaceCard } from './place-card.tsx'
import { useMemo } from 'react'

export const PlacesPreview = Modular.Component('PlacesPreview')
  .with(
    Modular.lifecycle(({ view }) => {
      const text = useMemo(
        () => JSON.stringify(view.chapter?.content) ?? '',
        [view.chapter?.content]
      )
      const places = view.places.filter((place) => text.includes(place.name))
      return { places }
    })
  )
  .with(
    Modular.render(({ lifecycle }) => (
      <UI.ScrollArea h="calc(100vh - 6rem)" mx={-16} mt={-8}>
        <UI.Stack p={16}>
          {lifecycle.places.map((place) => (
            <PlaceCard preview key={place.id} {...place} />
          ))}
        </UI.Stack>
      </UI.ScrollArea>
    ))
  )
