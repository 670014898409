import * as UI from '@mantine/core'
import Modular from './modular.ts'

const Book = Modular.Component<{
  book: { id: string; title: string }
}>('Book').with(
  Modular.render(({ props }) => (
    <UI.Card
      w={{ base: '100%', sm: 240 }}
      maw={320}
      p={0}
      key={props.book.id}
      radius="md"
      style={{
        aspectRatio: 12 / 17,
        boxShadow: '1px 1px 6px 0 rgba(0,0,0,0.2)',
        backgroundImage: `url(/books/${props.book.id}.png), url(/books/book.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      component="a"
      href={`/b/${props.book.id}`}
    ></UI.Card>
  ))
)

export const Books = Modular.Component('Books').with(
  Modular.render(({ screen, view }) => (
    <UI.Group
      mt={screen.isDesktop ? 64 : -32}
      pt={screen.isDesktop ? 0 : 4}
      px={screen.isDesktop ? 16 : 32}
      gap={screen.isDesktop ? 16 : 24}
      w="100%"
      align="flex-start"
      justify="center"
    >
      {view.books.map((book) => (
        <Book key={book.id} book={book} />
      ))}
    </UI.Group>
  ))
)
