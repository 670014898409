export const extract = <T extends string, V>(
  value: T,
  record: { [key in T]: V }
): V => {
  return record[value]
}

export const when = <V, C extends boolean, E = undefined>(
  condition: C,
  value: V,
  orElse?: E
): C extends true ? V : E => {
  if (condition) return value as C extends true ? V : E
  return orElse as C extends true ? V : E
}
