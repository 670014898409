export const HairFemale = () => (
  <svg
    fill="currentColor"
    height="1.25em"
    width="1.25em"
    viewBox="0 0 24 24"
    style={{ filter: 'drop-shadow(var(--mantine-color-dark-5) 0 0 1px)' }}
  >
    <circle cx={12} cy={12} r={6} fill="white" />
    <circle cx={12} cy={12} r={6} fill="currentColor" opacity={0.6} />
    <path
      d="M18.7999,6.39998 C16.9156,3.88761 14.5206,2.5 11.9999,2.5 C9.4791,2.5 7.08416,3.8876 5.19986,6.39998 C3.78336,8.28864 2.07418,10.8406 1.83823,13.5244 C1.71656,14.9082 1.98533,16.325 2.85681,17.6731 C3.71823,19.0055 5.12544,20.2052 7.17171,21.2535 C8.43101,21.8987 9.80284,21.0342 9.98053,19.771 C9.57716,19.5018 9.18071,19.1645 8.81878,18.7696 C7.80612,17.6649 6.99988,16.0473 6.99988,13.9999 C6.99988,10.5715 8.76982,8.26538 10.8989,7.65648 C11.1813,7.57573 11.4994,7.78814 11.5331,8.0799 C11.8183,10.5517 13.9073,12.4748 16.4498,12.4997 C17.1239,12.5063 16.9999,13.5609 16.9999,13.9999 C16.9999,16.0473 16.1936,17.6649 15.1809,18.7696 C14.8179,19.1656 14.4202,19.5038 14.0156,19.7734 C14.1758,21.0407 15.5338,21.9121 16.7942,21.3041 C18.9591,20.2597 20.4375,19.0607 21.3316,17.7191 C22.2393,16.3572 22.4949,14.9222 22.3392,13.525 C22.0394,10.8344 20.2008,8.26785 18.7999,6.39998 Z"
      fill="currentColor"
    />
  </svg>
)

export const HairMale = () => (
  <svg
    fill="currentColor"
    height="1.25em"
    width="1.25em"
    viewBox="0 0 24 24"
    style={{ filter: 'drop-shadow(var(--mantine-color-dark-5) 0 0 1px)' }}
  >
    <g transform="translate(0,-2)">
      <circle cx={12} cy={15} r={6.5} fill="white" />
      <circle cx={12} cy={15} r={6.5} fill="currentColor" opacity={0.6} />
      <path
        d={`
      M11.5,6 
      C14.2856,6 16.66,6.75154 17.5856,8.21198 
      C22,14.528129 18.4363788,16.6911072 18.2234259,17.5745543 
      
      L18,17.6586 
      L18,14.5 
      C18,12.567 16.433,11 14.5,11 
      C13.8868,11 13.3105,11.1577 12.8093,11.4347 
      C12.319,11.7057 11.681,11.7057 11.1907,11.4347 
      C10.6895,11.1577 10.1132,11 9.5,11 
      C7.63143333,11 6.10487333,12.4642744 6.00517885,14.3079645 
       Z`}
        fill="currentColor"
      />
      <path
        transform="scale(-1,1)"
        style={{ transformOrigin: 'center' }}
        d={`
      M11.5,6 
      C14.2856,6 16.66,6.75154 17.5856,8.21198 
      C22,14.528129 18.4363788,16.6911072 18.2234259,17.5745543 
      
      L18,17.6586 
      L18,14.5 
      C18,12.567 16.433,11 14.5,11 
      C13.8868,11 13.3105,11.1577 12.8093,11.4347 
      C12.319,11.7057 11.681,11.7057 11.1907,11.4347 
      C10.6895,11.1577 10.1132,11 9.5,11 
      C7.63143333,11 6.10487333,12.4642744 6.00517885,14.3079645 
       Z`}
        fill="currentColor"
      />
    </g>
  </svg>
)

export const Eye = () => (
  <svg
    fill="currentColor"
    height="1.25em"
    width="1.25em"
    viewBox="0 0 24 24"
    style={{ filter: 'drop-shadow(var(--mantine-color-dark-5) 0 0 1px)' }}
  >
    <circle cx={12} cy={12} r={5} fill="white" />
    <circle cx={12} cy={12} r={5} fill="currentColor" opacity={0.4} />
    <path
      d="M12,5 C15.6786,5 20.1621,7.41663 21.7292,10.9014 C21.8765,11.229 22,11.6119 22,12 C22,12.3881 21.8765,12.771 21.7292,13.0986 C20.1621,16.5834 15.6786,19 12,19 C8.32138,19 3.83788,16.5834 2.27082,13.0986 C2.12348,12.771 2,12.3881 2,12 C2,11.6119 2.12348,11.229 2.27082,10.9014 C3.83789,7.41663 8.32138,5 12,5 Z M12,8 C9.79086,8 8,9.79086 8,12 C8,14.2091 9.79086,16 12,16 C14.2091,16 16,14.2091 16,12 C16,9.79086 14.2091,8 12,8 Z M12,10 C13.1046,10 14,10.8954 14,12 C14,13.1046 13.1046,14 12,14 C10.8954,14 10,13.1046 10,12 C10,10.8954 10.8954,10 12,10 Z"
      fill="currentColor"
    />
  </svg>
)

export const Face = () => (
  <svg
    fill="currentColor"
    height="1.25em"
    width="1.25em"
    viewBox="0 0 24 24"
    style={{ filter: 'drop-shadow(var(--mantine-color-dark-5) 0 0 1px)' }}
  >
    <circle cx={12} cy={8} r={6} fill="white" />
    <circle cx={12} cy={8} r={6} fill="currentColor" opacity={0.7} />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M12,13 C14.3955,13 16.5753,13.6937 18.1777,14.6715 C18.9786,15.1602 19.6621,15.7363 20.156,16.3615 C20.642,16.9767 21,17.713 21,18.5 C21,19.3449 20.5889,20.0111 19.9973,20.4859 C19.4368,20.9359 18.6982,21.2336 17.9128,21.4416 C16.3353,21.8593 14.229,22 12,22 C9.77101,22 7.66466,21.8593 6.08716,21.4416 C5.30182,21.2336 4.56324,20.9359 4.00266,20.4859 C3.41114,20.0111 3,19.3449 3,18.5 C3,17.713 3.35805,16.9767 3.84397,16.3615 C4.33788,15.7363 5.02143,15.1602 5.82227,14.6715 C7.42467,13.6937 9.60453,13 12,13 Z M12,2 Z"
      fill="currentColor"
    ></path>
  </svg>
)
