import { nanoquery } from '@nanostores/query'
import { firestore, auth, FS } from '../service/firebase.ts'
import { computed } from 'nanostores'
import { $router } from './router.ts'

type Kind =
  | 'capital'
  | 'city'
  | 'village'
  | 'stronghold'
  | 'battlefield'
  | 'area'
  | 'friendly'
  | 'enemy'

export type Place = {
  id: string
  name: string
  kind: Kind
  description: string
  backstory: string
}

type PlacePayload<Keys extends keyof Place = keyof Place> = Pick<
  Place,
  Keys
> & { book: string }

const places = nanoquery({
  async fetcher(book) {
    const data = await FS.getDocs(
      FS.collection(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/places`
      )
    )
    return data.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
  }
})

export const $book = computed(
  $router,
  (page) => (page?.params as any)?.book || null
)
export const $places = places[0]<Place[]>([$book])
export const createPlace = places[1]<
  PlacePayload<'name'>,
  FS.DocumentReference
>(async ({ data, invalidate }) => {
  invalidate(data.book)
  const doc = FS.doc(
    firestore,
    'users',
    auth.currentUser!.uid,
    'books',
    data.book,
    'places',
    `${Date.now()}`
  )
  await FS.setDoc(doc, {
    name: data.name,
    kind: 'area',
    description: '',
    backstory: ''
  })
  return doc
})
export const updatePlace = places[1]<PlacePayload>(
  async ({ data: { book, id, ...data }, invalidate }) => {
    invalidate(book)
    return FS.updateDoc(
      FS.doc(
        firestore,
        'users',
        auth.currentUser!.uid,
        'books',
        book,
        'places',
        id
      ),
      data
    )
  }
)
export const optimisticPlaceUpdate = (data: PlacePayload) => {
  const docs = $places.get().data || []
  const index = docs.findIndex((doc: any) => doc.id === data.id)
  docs[index] = { ...data }
  places[2].mutateCache(data.book, [...docs])
}
