import { optimisticChapterUpdate, updateChapter } from './chapters.ts'
import { optimisticNoteUpdate, updateNote } from './notes.ts'
import {
  Character,
  optimisticCharacterUpdate,
  updateCharacter
} from './characters.ts'
import { optimisticPlaceUpdate, Place, updatePlace } from './places.ts'
import { atom } from 'nanostores'

const TIMEOUT = 1500
export const $dirtySet = atom(new Set())

function debounce(effect: any, func: any) {
  let timer: any
  let clean: any
  const f: any = (...args: any[]) => {
    $dirtySet.get().add(func)
    $dirtySet.set(new Set($dirtySet.get()))
    clean?.()
    clean = () => {
      clearTimeout(timer)
      document.removeEventListener('visibilitychange', commit)
      document.removeEventListener('blur', commit)
      window.removeEventListener('blur', commit)
    }
    const commit = async () => {
      clean()
      await func(...args)
      $dirtySet.get().delete(func)
      $dirtySet.set(new Set($dirtySet.get()))
    }
    effect(...args)
    timer = setTimeout(commit, TIMEOUT)
    f.commit = commit
    document.addEventListener('visibilitychange', commit)
    document.addEventListener('blur', commit)
    window.addEventListener('blur', commit)
  }

  return f
}

export function debounceChapterUpdate(chapterInfo: {
  id: string
  title: string
  book: string
}) {
  return debounce(
    (content: any, wordCount: number) =>
      optimisticChapterUpdate({ ...chapterInfo, content, wordCount }),
    (content: any, wordCount: number) =>
      updateChapter.mutate({ ...chapterInfo, content, wordCount })
  )
}

export function debounceNoteUpdate(noteInfo: {
  id: string
  title: string
  book: string
  draftFor?: string
}) {
  return debounce(
    (content: any) => optimisticNoteUpdate({ ...noteInfo, content }),
    (content: any) => updateNote.mutate({ ...noteInfo, content })
  )
}

export function debounceCharacterUpdate(characterInfo: {
  id: string
  book: string
}) {
  return debounce(
    (character: Omit<Character, 'id'>) =>
      optimisticCharacterUpdate({ ...characterInfo, ...character }),
    (character: Omit<Character, 'id'>) =>
      updateCharacter.mutate({ ...characterInfo, ...character })
  )
}

export function debouncePlaceUpdate(placeInfo: { id: string; book: string }) {
  return debounce(
    (place: Omit<Place, 'id'>) =>
      optimisticPlaceUpdate({ ...placeInfo, ...place }),
    (place: Omit<Place, 'id'>) => updatePlace.mutate({ ...placeInfo, ...place })
  )
}
