import { useCallback, useEffect, useMemo } from 'react'
import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { Editor } from './editor.tsx'
import { ChapterStatistics } from './chapter-statistics.tsx'
import { session } from '../state/chapters.ts'

export const Chapter = Modular.Component<{ note?: boolean }>('Chapter')
  .with(
    Modular.lifecycle(({ view, act, props }) => {
      const chapter = props.note ? view.note : view.chapter
      const chapterId = chapter?.id as string
      const chapterTitle = chapter?.title as string
      const bookId = view.book.id

      const handleChange = useMemo(() => {
        const debounce = props.note ? act.saveNote : act.saveChapter
        return debounce({ book: bookId, id: chapterId, title: chapterTitle })
      }, [act, props.note, bookId, chapterId, chapterTitle])
      const handleTitleChange = useCallback(
        (title: string) => {
          const action = props.note ? act.updateNote : act.updateChapter
          action.mutate({
            id: chapterId,
            book: bookId,
            title
          })
        },
        [act, props.note, chapterId, bookId]
      )

      useEffect(() => handleChange.commit, [handleChange])

      return { handleChange, handleTitleChange, chapter }
    })
  )
  .with(
    Modular.render(({ props, lifecycle, view, screen }) =>
      lifecycle.chapter ? (
        <UI.Box
          pos="relative"
          w={props.note && view.focus ? '50vw' : '100%'}
          mx="auto"
        >
          <UI.Text
            ff={props.note ? undefined : "'Crimson Pro', sans-serif"}
            fz={{ base: 24, sm: props.note ? 32 : 48 }}
            pos="absolute"
            ta="center"
            w="100%"
            style={{ zIndex: 10 }}
            top={'1.25em'}
            contentEditable
            onInput={(e) => {
              lifecycle.handleTitleChange(e.currentTarget.textContent ?? '')
            }}
            ref={(ref) => {
              if (
                ref &&
                (!ref.textContent ||
                  (ref as any).chapter !== lifecycle.chapter?.id)
              ) {
                ref.textContent = lifecycle.chapter?.title ?? ''
                ;(ref as any).chapter = lifecycle.chapter?.id
              }
            }}
          />
          <Editor
            key={lifecycle.chapter.id}
            content={lifecycle.chapter.content}
            shouldUpdate={lifecycle.chapter.session !== session}
            forceSet={props.note}
            onChange={lifecycle.handleChange}
          />
          {!props.note && !screen.isDesktop && (
            <UI.Group w="100%" px={32} pb={16} justify="flex-end">
              <ChapterStatistics
                chapters={view.chapters}
                chapter={view.chapters?.find(
                  (c) => c.id === (view.page?.params as any).chapter
                )}
              />
            </UI.Group>
          )}
        </UI.Box>
      ) : null
    )
  )
