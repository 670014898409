import * as UI from '@mantine/core'
import * as RTE from '@mantine/tiptap'
import { modals } from '@mantine/modals'
import Markdown from 'markdown-to-jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperPlane,
  faTrashCan,
  faUserAstronaut
} from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'
import { actions } from '../state/view.ts'
import {
  openAi,
  ChatOptions, convertToMarkdown,
  HistoryMessage,
  message as systemMessage
} from '../service/openai.ts'
import { auth } from '../service/firebase.ts'
import { voiceDetection } from '../service/voice-detection.ts'

import { SpeechBubble } from './speech-bubble.tsx'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

const getUserName = () => auth.currentUser?.displayName?.split(' ')[0] ?? 'User'

const Bubble = Modular.Component<{
  from: 'user' | 'assistant'
  message: string
  onDelete?: () => void
}>('Bubble').with(
  Modular.render(({ props, view, screen }) => (
    <UI.Stack w="100%" align="flex-start" gap={0}>
      <UI.Group
        mih={view.focus ? 42 : 0}
        align="center"
        bg={screen.isDesktop ? 'var(--color-columns)' : 'var(--color-background)'}
        pos="sticky"
        top={-17}
        w="calc(100% + 16px)"
        gap={4}
        style={{ zIndex: 1, borderBottom: '1px solid var(--color-border)' }}
        ml={-8}
        pl={8}
      >
        <UI.Group
          align="center"
          justify="center"
          mih={view.focus ? 42 : 32}
          w={view.focus ? 42 : 24}
        >
          {props.from === 'assistant' && (
            <UI.Avatar
              radius={0}
              size={view.focus ? 40 : 'sm'}
              src={view.focus ? '/qwill.png' : '/qwill.svg'}
            />
          )}
          {props.from === 'user' && !auth.currentUser?.photoURL && (
            <UI.Text ta="center" c="gray.5" size={view.focus ? 'md' : 'sm'}>
              <FontAwesomeIcon icon={faUserAstronaut} />
            </UI.Text>
          )}
          {props.from === 'user' && auth.currentUser?.photoURL && (
            <UI.Avatar
              size={view.focus ? 32 : 20}
              src={auth.currentUser.photoURL + '?ext=.jpg'}
            />
          )}
          <UI.ActionIcon
            pos="absolute"
            color="red"
            className="assistant-delete-message"
            onClick={() => {
              modals.openConfirmModal({
                title: <strong>Delete message</strong>,
                children: 'Do you really want to delete this message?',
                labels: { confirm: 'Delete', cancel: 'Cancel' },
                confirmProps: { color: 'red' },
                onConfirm: props.onDelete
              })
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </UI.ActionIcon>
        </UI.Group>

        <UI.Text fw="bold" fz={view.focus ? 15 : 14}>
          {props.from === 'assistant' ? 'Qwill' : getUserName()}
        </UI.Text>
      </UI.Group>
      <UI.Card
        w="100%"
        flex={1}
        mt={-8}
        py={0}
        pl={16}
        pr={view.focus ? 48 : 32}
        fz={14}
        ml={screen.isDesktop ? (view.focus ? 32 : 12) : 0}
        bg="transparent"
        className="assistant-answer"
        ta={screen.isDesktop ? undefined : 'justify'}
      >
        <Markdown options={{ forceWrapper: true, forceBlock: true }}>
          {props.message}
        </Markdown>
      </UI.Card>
    </UI.Stack>
  ))
)

const animationFrame = () =>
  new Promise((resolve) => requestAnimationFrame(resolve))

type ExtendedChatOptions = ChatOptions & {
  prompt: HistoryMessage
  fullPrompt?: HistoryMessage
  includeHistory: boolean
}

const allowedInHistory = ['review', undefined]
const cleanSystemMessages = (message: HistoryMessage) =>
  allowedInHistory.includes(message.system)

const getRepaintInterval = () => {
  return new Promise<number>((resolve) => {
    requestAnimationFrame((t1) => {
      requestAnimationFrame((t2) => {
        resolve(t2 - t1)
      })
    })
  })
}

const streamAnswer = async ({
                              history,
                              prompt,
                              fullPrompt = prompt,
                              contextMode = 'none',
                              modelMode = 'precise',
                              includeHistory = true
                            }: ExtendedChatOptions) => {
  const resp: HistoryMessage = { role: 'assistant', content: '' }
  const stream = openAi.chat({
    history: includeHistory
      ? [...history.filter(cleanSystemMessages), fullPrompt]
      : [fullPrompt],
    contextMode,
    modelMode
  })
  const repaintInterval = await getRepaintInterval()

  for await (const chunk of stream) {
    const nextText = chunk.text

    // Use smooth text addition on fast-refresh displays
    if (repaintInterval < 18) {
      const twoByTwo = nextText
        .split('')
        .map((c, i, a) => (i % 2 ? [] : [c, a[i + 1]]))
      for (const character of twoByTwo) {
        if (twoByTwo.length) {
          resp.content += character.join('')
          actions.setChatHistory([...history, prompt, resp])
          await animationFrame()
        }
      }
    } else {
      resp.content += nextText
      actions.setChatHistory([...history, prompt, resp])
    }
  }

  return resp
}

export const Assistant = Modular.Component('Assistant')
  .with(Modular.chat())
  .with(
    Modular.lifecycle(({ view, act, chat }) => {
      const editor = useEditor({
        extensions: [StarterKit]
      })

      const ask = async ({
                           details,
                           content,
                           system,
                           ...options
                         }: Omit<ChatOptions, 'history'> & {
        details?: string
        system?: string
        content: string
        includeHistory: boolean
      }) => {
        const prompt: HistoryMessage = { role: 'user', content }
        const history: HistoryMessage[] = [...chat.messages]

        await act.updateChatHistory.mutate({
          book: view.book.id,
          chapter: view.chapter!.id,
          history: [...history, prompt]
        })

        const resp = await streamAnswer({
          ...options,
          history,
          prompt,
          fullPrompt: details
            ? { ...prompt, content: [content, details].join(' ') }
            : { ...prompt }
        })

        if (system) {
          prompt.system = system
          resp.system = system
        }

        await act.updateChatHistory.mutate({
          book: view.book.id,
          chapter: view.chapter!.id,
          history: [...history, prompt, resp]
        })

        return resp.content
      }

      const message = async (content: string) =>
        await ask({
          content,
          contextMode: 'chapter',
          modelMode: 'precise',
          includeHistory: true
        })

      const summarize = async () => {
        const summary = await ask({
          content: systemMessage('summarizePrompt'),
          details: systemMessage('summarizeDetails'),
          contextMode: 'chapter',
          modelMode: 'precise',
          includeHistory: false,
          system: 'summary'
        })

        await act.updateChapter.mutate({
          book: view.book.id,
          id: view.chapter!.id,
          summary: summary as string
        })
      }

      const review = async () => {
        await ask({
          content: systemMessage('reviewPrompt'),
          details: systemMessage('reviewDetails'),
          contextMode: 'chapter',
          modelMode: 'precise',
          includeHistory: false,
          system: 'review'
        })
      }

      const history = async () => {
        const summary = await ask({
          content: systemMessage('historyPrompt'),
          details: systemMessage('historyDetails'),
          contextMode: 'none',
          modelMode: 'fast',
          includeHistory: true
        })

        await act.updateChatHistory.mutate({
          book: view.book.id,
          chapter: view.chapter!.id,
          history: [{ role: 'assistant', content: summary, system: 'history' }]
        })
      }

      const deleteMessage = async (message: HistoryMessage) => {
        const history = chat.messages.filter((m) => m !== message)
        await act.updateChatHistory.mutate({
          book: view.book.id,
          chapter: view.chapter!.id,
          history
        })
      }

      voiceDetection.handleMessage = message

      return {
        message,
        summarize,
        review,
        history,
        deleteMessage,
        editor
      }
    })
  )
  .with(
    Modular.render(({ lifecycle, view, screen, chat }) => (
      <UI.Stack h={view.qwill ? 'calc(100vh - 3.25rem)' : 'calc(100vh - 7rem)'}>
        <UI.Stack
          gap={8}
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            flexDirection: 'column-reverse'
          }}
          mx={-16}
          mt={!view.qwill ? -8 : 0}
          mb={-8}
          py={16}
          px={8}
          h="calc(100vh - 12rem)"
        >
          {chat.messages
            .filter((message) => message.system !== 'history')
            .reverse()
            .map((message, index) => (
              <Bubble
                key={index}
                from={message.role as 'assistant'}
                message={message.content as string}
                onDelete={() => lifecycle.deleteMessage(message)}
              />
            ))}
          {chat.messages.some((message: any) => message.system === 'history') && (
            <Bubble
              from="assistant"
              message="_Previous conversation has been summarized and collapsed._"
            />
          )}
          <UI.Text mb="auto" pb={32} fz={14} ta="center">
            Hello, how can I help you today? You can ask me anything, I'll try
            to help you out the best I can.
          </UI.Text>
          <UI.Group mt="auto" align="center" justify="center">
            <UI.Avatar radius={16} size={128} src="/qwill.png" />
          </UI.Group>
        </UI.Stack>

        {!screen.isDesktop && (
          <UI.Stack gap={8} mb={-8}>
            <UI.Group gap={8}>
              <UI.Button
                size="xs"
                px={4}
                flex={1}
                variant="light"
                onClick={lifecycle.summarize}
                disabled={!view.previousSummary}
                title={
                  !view.previousSummary
                    ? 'Cannot summarize without previous chapter summary'
                    : ''
                }
              >
                Summarize
              </UI.Button>
              <UI.Button
                size="xs"
                px={4}
                flex={1}
                variant="light"
                onClick={lifecycle.review}
                disabled={!view.previousSummary}
                title={
                  !view.previousSummary
                    ? 'Cannot review without previous chapter summary'
                    : ''
                }
              >
                Review
              </UI.Button>
              <UI.Button
                size="xs"
                px={4}
                flex={1}
                variant="light"
                disabled={chat.messages.length < 1}
                title={
                  chat.messages.length < 1 ? 'No chat history to collapse' : ''
                }
                onClick={lifecycle.history}
              >
                Collapse history
              </UI.Button>
            </UI.Group>
          </UI.Stack>
        )}

        {screen.isDesktop && <UI.Box mx="auto" mt={-4} mb={-12}>
          <SpeechBubble />
        </UI.Box>}

        <RTE.RichTextEditor className="assistant-textbox" editor={lifecycle.editor} onKeyUp={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && screen.isDesktop) {
            e.preventDefault()
            const content = convertToMarkdown(lifecycle.editor?.getHTML())
            lifecycle.message(content)
            lifecycle.editor?.chain().clearContent().run()
          }
        }}>
          <RTE.RichTextEditor.Content />
        </RTE.RichTextEditor>
        {!screen.isDesktop && (
          <UI.Button
            onClick={() => {
              const content = convertToMarkdown(lifecycle.editor?.getHTML())
              lifecycle.message(content)
              lifecycle.editor?.chain().clearContent().run()
            }}
            className="assistant-send"
            mt={-8}
            mb={8}
            rightSection={<FontAwesomeIcon size="sm" icon={faPaperPlane} />}
            flex="none"
          >
            Send
          </UI.Button>
        )}

        {screen.isDesktop && (
          <UI.Stack mt={-8} gap={0}>
            <UI.Group gap={8}>
              <UI.Text fw="bold" fz={12} c="gray">
                Quick actions:
              </UI.Text>
              <UI.Button
                size="xs"
                px={4}
                h={24}
                variant="subtle"
                onClick={lifecycle.summarize}
                disabled={!view.previousSummary}
                title={
                  !view.previousSummary
                    ? 'Cannot summarize without previous chapter summary'
                    : ''
                }
              >
                Summarize
              </UI.Button>
              <UI.Button
                size="xs"
                px={4}
                h={24}
                variant="subtle"
                onClick={lifecycle.review}
                disabled={!view.previousSummary}
                title={
                  !view.previousSummary
                    ? 'Cannot review without previous chapter summary'
                    : ''
                }
              >
                Review
              </UI.Button>
              <UI.Button
                size="xs"
                px={4}
                h={24}
                variant="subtle"
                disabled={chat.messages.length < 1}
                title={
                  chat.messages.length < 1 ? 'No chat history to collapse' : ''
                }
                onClick={lifecycle.history}
              >
                Collapse history
              </UI.Button>
            </UI.Group>
          </UI.Stack>
        )}
      </UI.Stack>
    ))
  )
