import OpenAI from 'openai'
import { ChatCompletionMessageParam } from 'openai/resources/chat/completions'
import { auth, authPromise, firestore, FS } from './firebase.ts'
import { approximateTokenSize } from 'tokenwise'
import TurndownService from 'turndown'

import { generateHTML } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { TextStyle } from '@tiptap/extension-text-style'

import { $editor, $speech, $speechBubble } from '../state/chat.ts'
import { $view } from '../state/view.ts'
import { $tab } from '../components/panel-right.tsx'
import { $isDesktop } from '../state/screen.ts'

export const TOKEN_LIMIT = {
  precise: 128000 / 2,
  fast: 128000 / 2
}

const turndownService = new TurndownService()

const messages = {
  english: {
    systemPrompt:
      `
You are Qwill, a helpfull book editor with a keen eye for detail and a deep understanding of language, style, and grammar.
Your task is to refine and improve written content provided by users, offering advanced copyediting techniques and suggestions to enhance the overall quality of the text. When a user submits a piece of writing, follow these steps:

1. Read through the content carefully, identifying areas that need improvement in terms of grammar, punctuation, spelling, syntax, and style.
2. Provide specific, actionable suggestions for refining the text, explaining the rationale behind each suggestion.
3. Offer alternatives for word choice, sentence structure, and phrasing to improve clarity, concision, and impact.
4. Ensure the tone and voice of the writing are consistent and appropriate for the intended audience and purpose.
5. Check for logical flow, coherence, and organization, suggesting improvements where necessary.
6. Provide feedback on the overall effectiveness of the writing, highlighting strengths and areas for further development.

Your suggestions should be constructive, insightful, and designed to help the user elevate the quality of their writing.
You congratulates when due but you do NOT overuse compliments. You give constructive critics when necessary but DO NOT always try to find weaknesses.
You never critic for the sake of it, instead you focus on critiquing where you can give improvement advice.
All your remarks, positive and negative, are augmented with one or two quotations from the submitted work to illustrate your point.
You have a very energetic tone and try to always end on a positive note.
You structure your answers and use bullet point lists whenever necessary.
You make extensive use of Markdown *bold*, __italic__ and lists to make your answers as readable as possible.
You never output code blocks.
No fuss answers, no overly complex words.
`,
    previousSummary: 'Here is the summary of the book before this chapter:\n\n',
    previousEnd: 'Here are the few last paragraphs of the previous chapter, in Markdown format:\n\n',
    currentSummary: 'Here is the summary of the chapter we are working on:\n\n',
    currentContent:
      'Here is the content of the chapter we are working on here, in Markdown format:\n\n',
    currentSelection:
      'Here is the currently selected text, in Markdown format. Ignore it in all situation, except if directly asked about the selected text. In any case, never repeat the selected text. Never quote the selected text. If asked about the selected text, only consider this text, not any reference to selected text in the rest of the conversation.\n\n',
    summarizePrompt: 'Please summarize the chapter so far.',
    summarizeDetails: `Separate your answers in three distinct parts:
          **The story so far**: summarize both what the situation was in previous chapters, and what we learned in this new chapter. Add as much details as required to keep a good understanding of the book.
          **Characters**: for all named characters, include everything we know about their personality and their journey. If a character was present in the previous summary but not in the current chapter, include what was said in the previous summary.
          **Worldbuilding**: include any detail that was introduced about the world building, including everything that was reported in the previous summary.
          Start your message by **The story so far:**.`,
    reviewPrompt: 'Please review the current chapter',
    reviewDetails:
      'focusing on its strength and weaknesses and offering suggestions for where things can be improved',
    historyPrompt: 'Summarize our conversation so far',
    historyDetails:
      'focusing on everything we improved and things you learned about the story, so that it can be used as basis of a future conversation between us. Only includes the summary in your output, starting with "Here is what we know so far".'
  },
  french: {
    systemPrompt: `You are Qwill, a helpfull book editor with a keen eye for detail and a deep understanding of language, style, and grammar.
Your task is to refine and improve written content provided by users, offering advanced copyediting techniques and suggestions to enhance the overall quality of the text. When a user submits a piece of writing, follow these steps:

1. Read through the content carefully, identifying areas that need improvement in terms of grammar, punctuation, spelling, syntax, and style.
2. Provide specific, actionable suggestions for refining the text, explaining the rationale behind each suggestion.
3. Offer alternatives for word choice, sentence structure, and phrasing to improve clarity, concision, and impact.
4. Ensure the tone and voice of the writing are consistent and appropriate for the intended audience and purpose.
5. Check for logical flow, coherence, and organization, suggesting improvements where necessary.
6. Provide feedback on the overall effectiveness of the writing, highlighting strengths and areas for further development.

Your suggestions should be constructive, insightful, and designed to help the user elevate the quality of their writing.
You congratulates when due but you do NOT overuse compliments. You give constructive critics when necessary but DO NOT always try to find weaknesses.
You never critic for the sake of it, instead you focus on critiquing where you can give improvement advice.
All your remarks, positive and negative, are augmented with one or two quotations from the submitted work to illustrate your point.
You have a very energetic tone and try to always end on a positive note.
You structure your answers and use bullet point lists whenever necessary.
You make extensive use of Markdown *bold*, __italic__ and lists to make your answers as readable as possible.
You never output code blocks.
No fuss answers, no overly complex words.
You always answer in French.
`,
    previousSummary: 'Voici le résumé du livre avant ce chapitre :\n\n',
    previousEnd: 'Voici les quelques derniers paragraphes du chapitre précédent, au format Markdown :\n\n',
    currentSummary:
      'Voici le résumé du chapitre sur lequel nous travaillons :\n\n',
    currentContent:
      'Voici le contenu du chapitre sur lequel nous travaillons ici, au format Markdown :\n\n',
    currentSelection:
      "Voici le texte actuellement sélectionné, au format Markdown. Ignore-le dans toutes les situations, sauf si on te parle expressement du texte sélectionné. Quoiqu'il advienne, ne répète jamais le texte sélectionné.\n\n",
    summarizePrompt: "Résume le chapitre jusqu'à présent.",
    summarizeDetails:
      `Sépare tes réponses en trois parties distinctes :
          **L'histoire jusqu'à présent** : résume à la fois ce que la situation était dans les chapitres précédents, et ce que nous avons appris dans ce nouveau chapitre. Ajoute autant de détails que nécessaire pour maintenir une bonne compréhension du livre.
          **Personnages** : pour tous les personnages nommés, inclus tout ce que nous savons sur leur personnalité et leur parcours. Si un personnage était présent dans le résumé précédent mais pas dans le chapitre actuel, inclus ce qui a été dit dans le résumé précédent.
          **Construction du monde** : inclus tout détail qui a été introduit sur la construction du monde, y compris tout ce qui a été rapporté dans le résumé précédent.
          Commence ton message par **L'histoire jusqu'à présent :**.`,
    reviewPrompt: 'Passe en revue le chapitre actuel',
    reviewDetails:
      'en te concentrant sur ses forces et ses faiblesses et en offrant des suggestions pour les endroits où les choses peuvent être améliorées',
    historyPrompt: "Résume notre conversation jusqu'à présent",
    historyDetails:
      "en te concentrant sur tout ce que nous avons amélioré et les choses que tu as apprises sur l'histoire, afin que cela puisse servir de base à une future conversation entre nous. N'inclus que le résumé dans ta sortie, en commençant par \"Voici ce que nous savons jusqu'à présent\"."
  }
}

export const message = <K extends keyof (typeof messages)['english']>(
  key: K
) => {
  const language = $view.get()?.book.language ?? 'english'
  return messages[language as 'english'][key] ?? messages.english[key]
}

const openAiPromise = authPromise
  .then(() => FS.getDoc(FS.doc(firestore, 'users', auth.currentUser!.uid)))
  .then(
    (userDoc) =>
      new OpenAI({
        apiKey: userDoc.get('apiKey'),
        dangerouslyAllowBrowser: true
      })
  )

export type HistoryMessage = ChatCompletionMessageParam & {
  system?: string
  error?: true
}
export type ChatOptions = {
  history: HistoryMessage[]
  contextMode?: 'chapter' | 'summary' | 'none'
  modelMode?: 'precise' | 'fast'
}

export function getTokenLength(message: ChatCompletionMessageParam) {
  return 6 + approximateTokenSize((message.content as string) ?? '')
}

export function convertToMarkdown(html?: string) {
  return turndownService.turndown(html?.replace(/<p><\/p>/, '<hr>') ?? '')
}

function addPreMessages(
  history: HistoryMessage[],
  contextMode: ChatOptions['contextMode'] = 'chapter'
): HistoryMessage[] {
  const editor = $editor.get()
  const content = convertToMarkdown($editor.get()?.getHTML())
  const selectionContent = editor?.state.selection.content().toJSON()?.content
  const selection = convertToMarkdown(selectionContent && generateHTML({ type: 'doc', content: selectionContent }, [StarterKit, TextStyle]))
  let currentSummary = $view.get()?.chapter?.summary
  const previousSummary = $view.get()?.previousSummary
  const previousChapter = $view.get()?.previousChapter
  const previousEnd = convertToMarkdown(generateHTML({ type: 'doc', content: previousChapter?.content.content.slice(-3) }, [StarterKit, TextStyle]))

  const contentTokenSize = getTokenLength({
    role: 'user',
    content: content as string
  })
  const summaryTokenSize = getTokenLength({
    role: 'user',
    content: currentSummary as string
  })

  if (summaryTokenSize > contentTokenSize) {
    currentSummary = ''
  }

  const prefixes: HistoryMessage[] = [
    {
      role: 'system',
      content: message('systemPrompt')
    }
  ]

  if (contextMode !== 'none') {
    if (previousSummary) {
      prefixes.push({
        role: 'system',
        content: message('previousSummary') + previousSummary
      })
    }

    if (previousEnd) {
      prefixes.push({
        role: 'system',
        content: message('previousEnd') + previousEnd
      })
    }

    if (contextMode === 'summary' && currentSummary) {
      prefixes.push({
        role: 'system' as const,
        content: message('currentSummary') + currentSummary
      })
    }

    if (content && (contextMode === 'chapter' || !currentSummary)) {
      prefixes.push({
        role: 'system' as const,
        content: message('currentContent') + content
      })
    }

    if (selection) {
      prefixes.push({
        role: 'system' as const,
        content: message('currentSelection') + selection
      })
    }
  }

  return [...prefixes, ...history]
}

export function dropMessages(messages: HistoryMessage[], limit: number) {
  let count = 0
  const withoutAssistant = [...messages].reverse().flatMap((message) => {
    count += getTokenLength(message)
    if (message.role !== 'assistant' || count < limit) {
      return [message]
    }
    return []
  })
  count = 0
  return withoutAssistant
    .flatMap((message) => {
      count += getTokenLength(message)
      if (count < limit) {
        return [message]
      }
      return []
    })
    .reverse()
}

const mean = (...values: number[]) => {
  return values.reduce((acc, value) => acc + value, 0) / values.length
}

export const openAi = {
  async *chat(options: Required<ChatOptions>) {
    const openai = await openAiPromise
    const messages = addPreMessages(
      dropMessages(options.history, TOKEN_LIMIT[options.modelMode]),
      options.contextMode
    ).map(({ ...message }) => {
      delete message.system
      delete message.error
      return message
    })

    const resp = await openai.chat.completions.create({
      model: options.modelMode === 'precise' ? 'gpt-4o' : 'gpt-4o-mini',
      messages,
      stream: true
    })
    const [mainStream, audioStream] = resp.tee()

    async function *playbackGenerator() {
      for await (const chunk of audioStream) {
        yield { text: chunk.choices?.[0].delta.content ?? '' }
      }
    }
    this.playback(playbackGenerator())

    for await (const chunk of mainStream) {
      yield { text: chunk.choices?.[0].delta.content ?? '' }
    }
  },
  async playback(audioStream: AsyncGenerator<{ text: string }>) {
    let text = ''
    let promise: any = null

    const canTalk = () => ($speech.get() || $tab.get() !== 'assistant') && $isDesktop.get()

    for await (const m of audioStream) {
      text += (m.text).replace(
        /[*_#]/g, '',
      )

      const _index = text.split('').reverse().findIndex((c) => ['.', '!', '?'].includes(c))
      const index = text.length - _index

      if (index > 200 && _index > 0 && canTalk()) {
        const prev = promise
        const toTell = text.slice(0, index).trim()

        text = text.slice(index)
        promise = this.talk(toTell, prev)
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }

    if (text && canTalk()) {
      const prev = promise
      promise = this.talk(text, prev)
    }

    await promise

    $speechBubble.set({ freq1: -1, freq2: 0, freq3: 0 })
  },
  async talk(text: string, previous: Promise<void>) {
    const openai = await openAiPromise

    // Create a MediaSource object
    const audio = new Audio()
    const mediaSource = new MediaSource()
    const audioContext = new AudioContext()
    audio.src = URL.createObjectURL(mediaSource)
    audio.playbackRate = 1.2
    const source = audioContext.createMediaElementSource(audio)
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 256
    source.connect(analyser)
    analyser.connect(audioContext.destination)

    // When the MediaSource is open, add a source buffer
    mediaSource.addEventListener('sourceopen', async () => {
      // Fetch the MP3 stream
      const stream = await openai.audio.speech.create({
        model: 'tts-1',
        voice: 'nova',
        response_format: 'mp3',
        input: text
      })
      await previous

      const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
      const reader = stream.body!.getReader();

      // Function to read and append chunks to the source buffer
      const readStream = async () => {
        const { done, value } = await reader.read();
        if (done) {
          mediaSource.endOfStream();
          return;
        }
        sourceBuffer.appendBuffer(value);
        sourceBuffer.addEventListener('updateend', readStream, { once: true });
      };

      readStream();
      audio.play();
    });
    let handle = 0
    const computeBubble = () => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(dataArray)
      const freq1 = (mean(...dataArray.sort().slice(-60)) / 128) || $speechBubble.get().freq1
      const freq2 = (mean(...dataArray.sort().slice(-20)) / 128) || $speechBubble.get().freq2
      const freq3 = (mean(...dataArray.sort().slice(-40)) / 128) || $speechBubble.get().freq3

      $speechBubble.set({ freq1, freq2, freq3 })
      handle = requestAnimationFrame(() => {
        handle = requestAnimationFrame(() => {
          handle = requestAnimationFrame(computeBubble)
        })
      })
    }
    computeBubble()

    await new Promise((resolve) => audio.addEventListener('ended', resolve))
    cancelAnimationFrame(handle)
  },
  async listen(file: Blob) {
    const openai = await openAiPromise
    const formData = new FormData();
    formData.append('file', file, 'audio.wav');
    return openai.audio.transcriptions.create({
      model: 'whisper-1',
      response_format: 'text',
      file: formData.get('file') as File
    })
  }
}
