// Your web app's Firebase configuration
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider
} from 'firebase/auth'
import { initializeFirestore, persistentLocalCache } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

const EXECUTE_MANUAL_BACKUP = false
const localhost = window.location.hostname.includes('localhost')

const firebaseConfig = {
  apiKey: 'AIzaSyAYvEWTlZ57Dsf0_pC0-CiWQvOXWnuFiJ8',
  authDomain: localhost ? 'compose.jvdsande.com' : window.location.hostname,
  projectId: 'jvdsande-compose',
  storageBucket: 'jvdsande-compose.appspot.com',
  messagingSenderId: '471043272063',
  appId: '1:471043272063:web:3bf0b1a49300a6341d3e4a'
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

const provider = new GoogleAuthProvider()
export const auth = getAuth()

export const authPromise = setPersistence(auth, browserLocalPersistence).then(
  async () => {
    await getRedirectResult(auth)
    if (!auth.currentUser) {
      return signInWithRedirect(auth, provider)
        .catch(err => alert(err.message))
    }
    return true
  }
)
  .catch(err => alert(err.message))

export const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  localCache: persistentLocalCache({})
})
export * as FS from 'firebase/firestore'

if (EXECUTE_MANUAL_BACKUP) {
  const functions = getFunctions(app)
  httpsCallable(functions, 'manualBackup')()
}
