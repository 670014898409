import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faClose,
  faCompress,
  faExpand,
  faFloppyDisk
} from '@fortawesome/free-solid-svg-icons'
import { ChapterStatistics } from './chapter-statistics.tsx'

export const TopBar = Modular.Component('TopBar').with(
  Modular.render(({ view, screen }) => (
    <>
      <UI.Group flex={1} miw={screen.isDesktop ? 240 : 0}>
        <UI.Breadcrumbs
          maw={screen.maxWidth ? screen.maxWidth + 320 : undefined}
          w="100%"
          flex={1}
          ml={view.focus ? 0 : 'auto'}
        >
          <UI.Anchor
            href="/"
            fw={view.page?.route === 'books' ? 'bold' : ''}
            onClick={() => view.toggleQwill(false)}
          >
            Books
          </UI.Anchor>
          {!!view.book.id && (
            <UI.Anchor
              href={view.getPagePath('book', { book: view.book.id })}
              fw="bold"
              onClick={() => view.toggleQwill(false)}
            >
              {view.book.title}
            </UI.Anchor>
          )}
        </UI.Breadcrumbs>
      </UI.Group>
      {screen.isDesktop && (
        <>
          <UI.Text fw="bold" ta="center" w="100%" maw="30rem">
            {view.chapter?.title ?? view.note?.title}
            <UI.Text
              c="indigo"
              fz={12}
              ml={8}
              display="inline"
              className="saving-indicator"
            >
              {view.unsaved && <FontAwesomeIcon icon={faFloppyDisk} />}
            </UI.Text>
          </UI.Text>
          <UI.Group flex={1} miw={240} align="center">
            {view.chapter && (
              <ChapterStatistics
                chapters={view.chapters}
                chapter={view.chapters?.find(
                  (c) => c.id === (view.page?.params as any).chapter
                )}
              />
            )}
            <UI.ActionIcon
              mt={-2}
              ml={view.chapter ? 0 : 'auto'}
              onClick={() => view.toggleFocus()}
              variant={view.focus ? 'light' : 'subtle'}
              color={view.focus ? 'indigo' : 'gray'}
            >
              <FontAwesomeIcon icon={view.focus ? faCompress : faExpand} />
            </UI.ActionIcon>
          </UI.Group>
        </>
      )}
      {view.qwill && (
        <UI.ActionIcon onClick={() => view.toggleQwill(false)} variant="subtle">
          <FontAwesomeIcon icon={faClose} />
        </UI.ActionIcon>
      )}

      {!screen.isDesktop && view.unsaved && (
        <UI.Text
          c="indigo"
          fz={16}
          display="inline"
          className="saving-indicator"
        >
          {view.unsaved && <FontAwesomeIcon icon={faFloppyDisk} />}
        </UI.Text>
      )}
      {!screen.isDesktop && !view.qwill && !!view.chapter && (
        <UI.Button variant="subtle" px={0} onClick={() => view.toggleQwill()}>
          <UI.Image h={35} w={35} src="/qwill.png" alt="" />
        </UI.Button>
      )}
      {!screen.isDesktop && !view.qwill && view.book.id && (
        <UI.ActionIcon
          color="indigo"
          variant="subtle"
          onClick={() => view.toggleExpanded()}
        >
          <FontAwesomeIcon icon={view.expanded ? faClose : faBars} />
        </UI.ActionIcon>
      )}
    </>
  ))
)
