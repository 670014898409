import { useEffect, useMemo } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from '@nanostores/react'
import * as UI from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCampground,
  faChessRook,
  faClose,
  faExplosion,
  faLandmark,
  faMountainSun,
  faPen,
  faShieldHeart,
  faSkull,
  faTreeCity
} from '@fortawesome/free-solid-svg-icons'
import { atom } from 'nanostores'
import Modular from './modular.ts'
import { Place } from '../state/places.ts'
import { extract } from '../utils.ts'

const $expanded = atom('')

export const PlaceCard = Modular.Component<
  Place & {
    preview?: boolean
  }
>('PlaceCard')
  .with(
    Modular.lifecycle(({ props, view, act }) => {
      const [editing, { toggle: edit }] = useDisclosure()
      const expanded = useStore($expanded) === props.id

      const handleChange = useMemo(
        () => act.savePlace({ id: props.id, book: view.book.id }),
        [act, props.id, view.book.id]
      )

      useEffect(() => {
        return () => handleChange.commit?.()
      }, [handleChange])

      return {
        update: (value: Partial<Place>) => () =>
          handleChange({
            ...props,
            ...value
          }),
        input:
          (field: keyof Place) =>
          (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            handleChange({
              ...props,
              [field]: e.currentTarget.value
            }),
        expanded,
        toggle: () => $expanded.set(expanded ? '' : props.id),
        editing,
        edit
      }
    })
  )
  .with(
    Modular.render(({ props, lifecycle }) => {
      if (props.preview && !lifecycle.editing) {
        return (
          <UI.Card withBorder bg="var(--color-columns)" radius="md">
            <UI.Group
              align="center"
              onClick={lifecycle.toggle}
              style={{ cursor: 'pointer' }}
            >
              <UI.Text fw="bold">{props.name}</UI.Text>
              <UI.Group ml="auto">
                <UI.Text
                  size="md"
                  c={extract(props.kind, {
                    capital: 'indigo.5',
                    city: 'indigo.5',
                    village: 'indigo.5',
                    stronghold: 'indigo.5',
                    battlefield: 'red.9',
                    area: 'lime.9',
                    friendly: 'grape.9',
                    enemy: 'red.9'
                  })}
                  title={props.kind}
                  style={{
                    filter: 'drop-shadow(var(--mantine-color-dark-5) 0 0 1px)'
                  }}
                >
                  <FontAwesomeIcon
                    icon={extract(props.kind, {
                      capital: faLandmark,
                      city: faTreeCity,
                      village: faCampground,
                      stronghold: faChessRook,
                      battlefield: faExplosion,
                      area: faMountainSun,
                      friendly: faShieldHeart,
                      enemy: faSkull
                    })}
                  />
                </UI.Text>
                <UI.ActionIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    lifecycle.edit()
                  }}
                  color="gray"
                  size="sm"
                  variant="subtle"
                >
                  <FontAwesomeIcon size="xs" icon={faPen} />
                </UI.ActionIcon>
              </UI.Group>
            </UI.Group>

            <UI.Collapse in={lifecycle.expanded}>
              <UI.Stack mt={8}>
                {props.description && (
                  <UI.Stack gap={0}>
                    <UI.Text fz={14} fw="bold">
                      Description
                    </UI.Text>
                    <UI.Text fz={14}>{props.description}</UI.Text>
                  </UI.Stack>
                )}
                {props.backstory && (
                  <UI.Stack gap={0}>
                    <UI.Text fz={14} fw="bold">
                      Backstory
                    </UI.Text>
                    <UI.Text fz={14}>{props.backstory}</UI.Text>
                  </UI.Stack>
                )}
                {!props.description && !props.backstory && (
                  <UI.Text fs="italic" fz={14} ta="center">
                    No description or backstory yet
                  </UI.Text>
                )}
              </UI.Stack>
            </UI.Collapse>
          </UI.Card>
        )
      }

      return (
        <UI.Card withBorder bg="var(--color-columns)" radius="md">
          <UI.Stack>
            <UI.Group w="100%">
              <UI.Text fw="bold">{props.name}</UI.Text>
              {props.preview && (
                <UI.ActionIcon
                  ml="auto"
                  onClick={(e) => {
                    e.stopPropagation()
                    lifecycle.edit()
                  }}
                  color="gray"
                  size="sm"
                  variant="subtle"
                >
                  <FontAwesomeIcon size="xs" icon={faClose} />
                </UI.ActionIcon>
              )}
            </UI.Group>

            <UI.Group align="flex-end">
              <UI.TextInput
                name="name"
                label="Name"
                value={props.name}
                onChange={lifecycle.input('name')}
                flex={1}
              />
              <UI.InputWrapper label="Kind">
                <UI.ActionIconGroup py={4}>
                  {(
                    [
                      'capital',
                      'city',
                      'village',
                      'stronghold',
                      'battlefield',
                      'area',
                      'friendly',
                      'enemy'
                    ] as const
                  ).map((kind, index, { length }) => (
                    <UI.ActionIcon
                      size="md"
                      onClick={lifecycle.update({ kind })}
                      color={extract(kind, {
                        capital: 'indigo.5',
                        city: 'indigo.5',
                        village: 'indigo.5',
                        stronghold: 'indigo.5',
                        battlefield: 'red.9',
                        area: 'lime.9',
                        friendly: 'grape.9',
                        enemy: 'red.9'
                      })}
                      variant={props.kind === kind ? 'filled' : 'subtle'}
                      c={props.kind === kind ? 'white' : 'gray.5'}
                      style={{
                        border: '1px solid var(--mantine-color-gray-4)',
                        borderRightWidth: index === length - 1 ? 1 : 0,
                        borderLeftWidth: index ? 0 : 1
                      }}
                      title={kind}
                    >
                      <FontAwesomeIcon
                        icon={extract(kind, {
                          capital: faLandmark,
                          city: faTreeCity,
                          village: faCampground,
                          stronghold: faChessRook,
                          battlefield: faExplosion,
                          area: faMountainSun,
                          friendly: faShieldHeart,
                          enemy: faSkull
                        })}
                      />
                    </UI.ActionIcon>
                  ))}
                </UI.ActionIconGroup>
              </UI.InputWrapper>
            </UI.Group>

            <UI.Textarea
              value={props.description}
              onChange={lifecycle.input('description')}
              label="Description"
              autosize
            />
            <UI.Textarea
              value={props.backstory}
              onChange={lifecycle.input('backstory')}
              label="Backstory"
              autosize
            />
          </UI.Stack>
        </UI.Card>
      )
    })
  )
