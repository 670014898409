import { nanoquery } from '@nanostores/query'
import { firestore, auth, FS, authPromise } from '../service/firebase.ts'
import { computed } from 'nanostores'
import { $router } from './router.ts'
import { session } from './chapters.ts'

export type Note = {
  id: string
  title: string
  content: any
  session?: number
  draftFor?: string
}

type NotePayload<Keys extends keyof Note = keyof Note> = Pick<Note, Keys> & {
  book: string
}

function transformNote(doc: FS.DocumentSnapshot) {
  return {
    id: doc.id,
    ...doc.data()
  }
}

const notes = nanoquery({
  async fetcher(book) {
    const data = await FS.getDocs(
      FS.collection(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/notes`
      )
    )
    return data.docs.map(transformNote)
  }
})

export const $book = computed(
  $router,
  (page) => (page?.params as any)?.book || null
)
export const $notes = notes[0]<Note[]>([$book])
export const createNote = notes[1]<NotePayload<'title' | 'draftFor'>, FS.DocumentReference>(
  async ({ data, invalidate }) => {
    invalidate(data.book)
    return FS.addDoc(
      FS.collection(
        firestore,
        `users/${auth.currentUser?.uid}/books/${data.book}/notes`
      ),
      { title: data.title, draftFor: data.draftFor ?? null, content: {} }
    )
  }
)
export const updateNote = notes[1]<Partial<NotePayload>>(
  async ({ data: { book, id, ...data } }) => {
    data.session = session
    return FS.updateDoc(
      FS.doc(
        firestore,
        `users/${auth.currentUser?.uid}/books/${book}/notes/${id}`
      ),
      data
    )
  }
)

export const optimisticNoteUpdate = (data: NotePayload) => {
  const docs = $notes.get().data || []
  const index = docs.findIndex((doc: any) => doc.id === data.id)
  docs[index] = { ...data, session }
  notes[2].mutateCache(data.book, [...docs])
}

authPromise.then(() => {
  const bookSubscriptions: Record<string, FS.Unsubscribe> = {}
  $book.subscribe((book) => {
    if (book) {
      bookSubscriptions[book]?.()

      bookSubscriptions[book] = FS.onSnapshot(
        FS.collection(
          firestore,
          `users/${auth.currentUser?.uid}/books/${book}/notes`
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(transformNote)
          notes[2].mutateCache(book, docs)
        }
      )
    }
  })
})
