import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { NotePreview } from './note-preview.tsx'
import { persistentAtom } from '@nanostores/persistent'
import { useStore } from '@nanostores/react'
import { CharactersPreview } from './characters-preview.tsx'
import { PlacesPreview } from './places-preview.tsx'
import { Assistant } from './assistant.tsx'
import { when } from '../utils.ts'

export const $tab = persistentAtom<null | string>('rightPanel', 'notes', {
  encode: JSON.stringify,
  decode: JSON.parse
})

export const PanelRight = Modular.Component('PanelRight').with(
  Modular.render(({ view, screen }) => {
    const tab = useStore($tab)

    if (view.page?.route !== 'chapter' || !screen.isDesktop) {
      return null
    }

    return (
      <UI.Tabs
        value={tab}
        onChange={$tab.set}
        w="calc(100% + 32px)"
        m={-16}
        h="calc(100vh - 3rem)"
      >
        <UI.Tabs.List style={{ flexWrap: 'nowrap' }} grow h={48}>
          <UI.Tabs.Tab
            fw="bold"
            c={when(tab !== 'notes', 'gray.5')}
            value="notes"
          >
            Notes
          </UI.Tabs.Tab>
          {view.book.fiction && (
            <UI.Tabs.Tab
              fw="bold"
              c={when(tab !== 'characters', 'gray.5')}
              value="characters"
            >
              Characters
            </UI.Tabs.Tab>
          )}
          {view.book.fiction && (
            <UI.Tabs.Tab
              fw="bold"
              c={when(tab !== 'places', 'gray.5')}
              value="places"
            >
              Places
            </UI.Tabs.Tab>
          )}
          <UI.Tabs.Tab
            fw="bold"
            c={when(tab !== 'assistant', 'gray.5')}
            value="assistant"
          >
            Assistant
          </UI.Tabs.Tab>
        </UI.Tabs.List>

        <UI.Card bg="transparent" pt={8}>
          <UI.Tabs.Panel value="notes">
            <NotePreview />
          </UI.Tabs.Panel>
          <UI.Tabs.Panel value="characters">
            <CharactersPreview />
          </UI.Tabs.Panel>
          <UI.Tabs.Panel value="places">
            <PlacesPreview />
          </UI.Tabs.Panel>
          <UI.Tabs.Panel value="assistant">
            <Assistant />
          </UI.Tabs.Panel>
        </UI.Card>
      </UI.Tabs>
    )
  })
)
