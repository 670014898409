import * as UI from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartSimple,
  faPenNib,
  faPercent
} from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'

export const ChapterStatistics = Modular.Component<{
  chapters?: { wordCount?: number }[]
  chapter?: { wordCount?: number }
}>('ChapterStatistics')
  .with(
    Modular.lifecycle(({ props }) => {
      const dataset = (props.chapters ?? []).filter(
        (chapter) => (chapter.wordCount ?? 0) > 100
      )
      const mean = Math.round(
        dataset.reduce((acc, val) => acc + (val.wordCount ?? 0), 0) /
          dataset.length || 1
      )
      const count = props.chapter?.wordCount ?? 0
      const variation = Math.round((count / mean) * 1000 - 1000) / 10

      return { mean, variation, count }
    })
  )
  .with(
    Modular.render(({ lifecycle }) => (
      <UI.Group ml="auto" gap={24} c="var(--mantine-color-gray-6)">
        <UI.Group gap={8}>
          <UI.Text fz={12}>
            <FontAwesomeIcon icon={faPenNib} />
          </UI.Text>
          <UI.Text fz={12} fw={600}>
            {lifecycle.count}
          </UI.Text>
        </UI.Group>
        <UI.Group gap={8}>
          <UI.Text fz={12}>
            <FontAwesomeIcon icon={faChartSimple} />
          </UI.Text>
          <UI.Text fz={12} fw={600}>
            {lifecycle.mean}
          </UI.Text>
        </UI.Group>
        <UI.Group gap={8}>
          <UI.Text fz={12} c={lifecycle.variation > 0 ? 'teal.9' : 'pink.9'}>
            <FontAwesomeIcon icon={faPercent} />
          </UI.Text>
          <UI.Text
            fz={12}
            fw={600}
            c={lifecycle.variation > 0 ? 'teal.9' : 'pink.9'}
          >
            {lifecycle.variation > 0 ? '+' : ''}
            {lifecycle.variation}%
          </UI.Text>
        </UI.Group>
      </UI.Group>
    ))
  )
