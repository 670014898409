import { $recording } from '../state/chat.ts'
import { openAi } from './openai.ts'
import { $isDesktop } from '../state/screen.ts'


export const voiceDetection = {
  talking: false,
  handle: 0,
  reportHandle: 0,
  report: () => {
    if (voiceDetection.talking && !voiceDetection.handle) {
      voiceDetection.handle = window.setTimeout(async () => {
        if (voiceDetection.talking) {
          if (!myvad) {
            // @ts-expect-error - from CDN
            myvad = await vad.MicVAD.new({
              onFrameProcessed: (prob: { isSpeech: number }) => {
                if (prob.isSpeech > 0.9) {
                  speechDetected = true
                }
              }
            })
          }

          speechDetected = false
          myvad?.start()
          $recording.set(true)

          const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
          mediaRecorder = new MediaRecorder(stream)

          const audioContext = new AudioContext()
          const source = audioContext.createMediaStreamSource(stream)
          const analyser = audioContext.createAnalyser()
          analyser.fftSize = 256
          source.connect(analyser)

          mediaRecorder.ondataavailable = event => {
            audioChunks.push(event.data)
          }

          let handle = 0
          mediaRecorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })
            audioChunks.length = 0
            cancelAnimationFrame(handle)
            analyser.disconnect()
            source.disconnect()
            myvad?.pause()

            if (speechDetected) {
              openAi.listen(audioBlob).then((text) => {
                voiceDetection.handleMessage(text as unknown as string)
              })
            }
          }

          mediaRecorder.start()

          const measure = () => {
            const dataArray = new Uint8Array(analyser.frequencyBinCount)
            analyser.getByteFrequencyData(dataArray)
            const container = document.querySelector('.container') as HTMLInputElement

            const val = Math.max(...dataArray)

            container?.style.setProperty('--scale', Math.max(1, val / 168) + '')
            handle = requestAnimationFrame(measure)
          }
          measure()
        }
      }, 500)
    }

    if (!voiceDetection.talking) {
      clearTimeout(voiceDetection.handle)
      voiceDetection.handle = 0
    }

    if (!voiceDetection.talking && $recording.get()) {
      $recording.set(false)
      mediaRecorder?.stop()
    }
  },
  startTalking: () => {
    clearTimeout(voiceDetection.handle)
    cancelAnimationFrame(voiceDetection.reportHandle)
    voiceDetection.reportHandle = requestAnimationFrame(voiceDetection.report)
  },
  stopTalking: () => {
    clearTimeout(voiceDetection.handle)
    cancelAnimationFrame(voiceDetection.reportHandle)
    voiceDetection.report()
  },
  handleMessage: (() => {
  }) as (text: string) => void
}

let mediaRecorder: MediaRecorder
let speechDetected = false
let myvad: { start: () => void, pause: () => void } | null = null
const audioChunks: Blob[] = []

window.addEventListener('keydown', (e) => {
  voiceDetection.talking = (e.ctrlKey || e.metaKey) && e.shiftKey && $isDesktop.get()

  if (voiceDetection.talking) {
    voiceDetection.startTalking()
  }
})
window.addEventListener('keyup', (e) => {
  voiceDetection.talking = (e.ctrlKey || e.metaKey) && e.shiftKey && $isDesktop.get()

  if (!voiceDetection.talking) {
    voiceDetection.stopTalking()
  }
})
