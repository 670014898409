import { useStore } from '@nanostores/react'
import * as UI from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'

import Modular from './modular.ts'

import { $speech, $speechBubble } from '../state/chat.ts'
import { voiceDetection } from '../service/voice-detection.ts'

export const SpeechBubble = Modular.Component('SpeechBubble')
  .with(Modular.lifecycle(() => {
    const speech = useStore($speech)
    const speechBubble = useStore($speechBubble)

    return {
      speech, speechBubble, toggleSpeech: () => {
        if (!voiceDetection.talking) {
          $speech.set(!$speech.get())
        }
      }
    }
  }))
  .with(Modular.render(({ lifecycle }) => {
    if (!lifecycle.speech) {
      return <UI.ActionIcon onClick={lifecycle.toggleSpeech} variant="subtle" c="var(--color-tools-border)">
        <FontAwesomeIcon icon={faMicrophoneSlash} />
      </UI.ActionIcon>
    }

    if (lifecycle.speechBubble.freq1 < 0) {
      return <UI.ActionIcon onClick={lifecycle.toggleSpeech} variant="subtle" c="var(--color-tools-border)">
        <FontAwesomeIcon icon={faMicrophone} />
      </UI.ActionIcon>
    }

    return (
      <UI.ActionIcon onClick={lifecycle.toggleSpeech} variant="subtle">
        <UI.Group h={15} mih={15} mah={15} gap={3} align="center">
          <UI.Box w={3} h={Math.min(3 + lifecycle.speechBubble.freq1 * 10, 15)} style={{ borderRadius: 3 }}
                  bg="var(--color-tools-border)" />
          <UI.Box w={3} h={Math.min(3 + lifecycle.speechBubble.freq2 * 10, 15)} style={{ borderRadius: 3 }}
                  bg="var(--color-tools-border)" />
          <UI.Box w={3} h={Math.min(3 + lifecycle.speechBubble.freq3 * 10, 15)} style={{ borderRadius: 3 }}
                  bg="var(--color-tools-border)" />
        </UI.Group>
      </UI.ActionIcon>
    )
  }))
