import { nanoquery } from '@nanostores/query'
import * as LZ from 'lz-string'
import { firestore, auth, FS } from '../service/firebase.ts'
import { atom, computed } from 'nanostores'
import { $router } from './router.ts'
import { HistoryMessage } from '../service/openai.ts'
import { Editor } from '@tiptap/react'

export type Chat = {
  id: string
  history: HistoryMessage[]
}

const chat = nanoquery({
  async fetcher(book, chapter) {
    const data = await FS.getDoc(
      FS.doc(
        firestore,
        'users',
        auth.currentUser!.uid,
        'books',
        book as string,
        'chats',
        chapter as string
      )
    )
    const content = { id: data.id, ...data.data() } as
      | Chat
      | { id: string; history: string }
    if (typeof content.history === 'string') {
      content.history = JSON.parse(
        LZ.decompressFromEncodedURIComponent(content.history)
      )
    }

    return content as Chat
  }
})

export const $editor = atom<Editor | null>(null)
const $book = computed($router, (page) =>
  page?.route === 'books' ? null : page?.params.book ?? null
)
const $chapter = computed($router, (page) =>
  page?.route === 'chapter' ? page?.params.chapter ?? null : null
)

export const $chat = chat[0]<Chat>([$book, $chapter])
export const $speech = atom(false)
export const $speechBubble = atom({
  freq1: -1,
  freq2: 0,
  freq3: 0
})
export const $recording = atom(false)

export const updateChatHistory = chat[1]<
  {
    book: string
    chapter: string
    history: HistoryMessage[]
  },
  void
>(async ({ data, getCacheUpdater }) => {
  const [updater, current] = getCacheUpdater<Chat>(data.book + data.chapter)
  const history = data.history
  updater({ id: data.chapter, ...current, history })
  const doc = FS.doc(
    firestore,
    'users',
    auth.currentUser!.uid,
    'books',
    data.book,
    'chats',
    data.chapter
  )
  return FS.setDoc(doc, {
    history: LZ.compressToEncodedURIComponent(JSON.stringify(history))
  })
})

export const setChatHistory = (history: HistoryMessage[]) =>
  $chat.mutate({
    ...$chat.get().data!,
    history
  })


export const $chatView = computed([
  $chat,
], (chat) => ({ messages: chat?.data?.history ?? [] }))
