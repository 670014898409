import * as UI from '@mantine/core'
import Modular from './modular.ts'
import { CharacterCard } from './character-card.tsx'
import { useMemo } from 'react'

export const CharactersPreview = Modular.Component('CharactersPreview')
  .with(
    Modular.lifecycle(({ view }) => {
      const text = useMemo(
        () => JSON.stringify(view.chapter?.content) ?? '',
        [view.chapter?.content]
      )
      const characters = view.characters.filter((character) =>
        text.includes(character.name)
      )
      return { characters }
    })
  )
  .with(
    Modular.render(({ lifecycle }) => (
      <UI.ScrollArea h="calc(100vh - 6rem)" mx={-16} mt={-8}>
        <UI.Stack p={16}>
          {lifecycle.characters.map((character) => (
            <CharacterCard preview key={character.id} {...character} />
          ))}
        </UI.Stack>
      </UI.ScrollArea>
    ))
  )
