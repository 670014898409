import { ModularComponent } from '@modular-component/core'
import { lifecycle, render } from '@modular-component/default'
import { useStore } from '@nanostores/react'
import { $view, actions } from '../state/view.ts'
import { $chatView } from '../state/chat.ts'
import { $screen } from '../state/screen.ts'

const Modular = {
  Component: <P extends object>(displayName?: string) =>
    ModularComponent<P>(displayName)
      .with(Modular.view())
      .with(Modular.act())
      .with(Modular.screen()),
  render,
  lifecycle,
  view: () => ({
    field: 'view' as const,
    useStage: () => useStore($view)
  }),
  chat: () => ({
    field: 'chat' as const,
    useStage: () => useStore($chatView)
  }),
  screen: () => ({
    field: 'screen' as const,
    useStage: () => useStore($screen)
  }),
  act: () => ({
    field: 'act' as const,
    useStage: () => actions
  })
}

export default Modular
