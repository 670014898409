import * as UI from '@mantine/core'
import * as DND from '@hello-pangea/dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconDefinition,
  faBookBookmark,
  faClipboard,
  faPlus,
  faStar,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'

const SectionTitle = Modular.Component<
  React.PropsWithChildren<{
    icon: IconDefinition
  }>
>('SectionTitle').with(
  Modular.render(({ props }) => (
    <UI.Group gap={8}>
      <UI.Text c="indigo.3">
        <FontAwesomeIcon style={{ width: 16, height: 14 }} icon={props.icon} />
      </UI.Text>
      <UI.Text fw="bold">{props.children}</UI.Text>
    </UI.Group>
  ))
)

const Entities = Modular.Component('Entities').with(
  Modular.render(({ view }) => (
    <UI.Stack gap={8}>
      <SectionTitle icon={faStar}>Entities</SectionTitle>
      <UI.Anchor
        fw={view.page?.route === 'characters' ? 'bold' : 'normal'}
        href={view.getPagePath('characters', { book: view.book.id })}
        onClick={() => view.toggleExpanded(false)}
      >
        Characters
      </UI.Anchor>
      <UI.Anchor
        fw={view.page?.route === 'places' ? 'bold' : 'normal'}
        href={view.getPagePath('places', { book: view.book.id })}
        onClick={() => view.toggleExpanded(false)}
      >
        Places
      </UI.Anchor>
    </UI.Stack>
  ))
)

const NoteList = Modular.Component('NoteList').with(
  Modular.render(({ view, act, screen }) => (
    <UI.Stack gap={8}>
      <SectionTitle icon={faClipboard}>Notes</SectionTitle>

      {view.notes.map((note) => (
        <UI.Anchor
          key={note.id}
          fw={(view.page?.params as any).note === note.id ? 'bold' : 'normal'}
          href={view.getPagePath('note', {
            book: view.book.id,
            note: note.id
          })}
          onClick={() => view.toggleExpanded(false)}
        >
          {note.title}
        </UI.Anchor>
      ))}

      <form
        onSubmit={async (e) => {
          e.preventDefault()
          const { value: note } = e.currentTarget.elements[
            'note' as any
          ] as HTMLInputElement
          e.currentTarget.reset()
          const res = await act.createNote.mutate({
            book: view.book.id,
            title: note
          })
          await act.updateBook.mutate({
            ...view.book,
            notes: [...(view.book.notes ?? []), res.id]
          })
        }}
      >
        <UI.Group gap={8} mt={8} maw={screen.maxWidth}>
          <UI.TextInput name="note" flex={1} size="xs" placeholder="New note" />
          <UI.ActionIcon type="submit" variant="light" size="input-xs">
            <FontAwesomeIcon icon={faPlus} />
          </UI.ActionIcon>
        </UI.Group>
      </form>
    </UI.Stack>
  ))
)

const ChapterList = Modular.Component('ChapterList').with(
  Modular.render(({ view, act, screen }) => (
    <UI.Stack gap={8}>
      <SectionTitle icon={faBookBookmark}>Chapters</SectionTitle>

      <DND.DragDropContext
        onDragEnd={({ source, destination }) => {
          if (destination && source.index !== destination.index) {
            act.reorderChapters(view.book, source.index, destination.index)
          }
        }}
      >
        <DND.Droppable droppableId="chapters" direction="vertical">
          {(...droppable) => (
            <UI.Stack
              gap={0}
              ref={droppable[0].innerRef}
              style={{ userSelect: 'none' }}
              {...droppable[0].droppableProps}
            >
              {view.chapters.map((chapter, index) => (
                <DND.Draggable
                  key={chapter.id}
                  draggableId={chapter.id}
                  index={index}
                >
                  {(...draggable) => {
                    return (
                      <UI.Group
                        h={32}
                        key={chapter.id}
                        gap={8}
                        wrap="nowrap"
                        {...draggable[0].draggableProps}
                        ref={draggable[0].innerRef}
                      >
                        <UI.Text
                          fz={12}
                          c="gray.4"
                          {...draggable[0].dragHandleProps}
                        >
                          <FontAwesomeIcon icon={faGripVertical} />
                        </UI.Text>
                        <UI.Anchor
                          fw={
                            (view.page?.params as any).chapter === chapter.id
                              ? 'bold'
                              : 'normal'
                          }
                          href={view.getPagePath('chapter', {
                            book: view.book.id,
                            chapter: chapter.id
                          })}
                          onClick={() => view.toggleExpanded(false)}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {chapter.title}
                        </UI.Anchor>
                      </UI.Group>
                    )
                  }}
                </DND.Draggable>
              ))}
              {droppable[0].placeholder}
            </UI.Stack>
          )}
        </DND.Droppable>
      </DND.DragDropContext>

      <form
        onSubmit={async (e) => {
          e.preventDefault()
          const { value: chapter } = e.currentTarget.elements[
            'chapter' as any
          ] as HTMLInputElement
          e.currentTarget.reset()
          const { id } = await act.createChapter.mutate({
            book: view.book.id,
            title: chapter
          })
          await act.updateBook.mutate({
            ...view.book,
            chapters: [...(view.book.chapters ?? []), id]
          })
        }}
      >
        <UI.Group gap={8} mt={8} maw={screen.maxWidth}>
          <UI.TextInput
            name="chapter"
            flex={1}
            size="xs"
            placeholder="New chapter"
          />
          <UI.ActionIcon type="submit" variant="light" size="input-xs">
            <FontAwesomeIcon icon={faPlus} />
          </UI.ActionIcon>
        </UI.Group>
      </form>
    </UI.Stack>
  ))
)

export const PanelLeft = Modular.Component('PanelLeft').with(
  Modular.render(({ view, screen }) => {
    if (view.page?.route === 'books') {
      return null
    }

    if (view.qwill) {
      return null
    }

    return (
      <>
        <UI.Collapse in={view.expanded}>
          <UI.Stack
            w="100%"
            maw={screen.maxWidth}
            ml="auto"
            gap={32}
            py={screen.isDesktop ? 0 : 16}
            mah="calc(100vh - 56px)"
            style={{ overflowY: 'auto' }}
          >
            {view.book.fiction && <Entities />}
            <NoteList />
            <ChapterList />
          </UI.Stack>
        </UI.Collapse>
      </>
    )
  })
)
