import { useEffect, useRef } from 'react'
import { atom } from 'nanostores'
import { useStore } from '@nanostores/react'
import { RichTextEditor } from '@mantine/tiptap'
import * as UI from '@mantine/core'
import {
  useEditor,
  BubbleMenu,
  FloatingMenu,
  Extension,
  textInputRule
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Table } from '@tiptap/extension-table'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { Color } from '@tiptap/extension-color'
import { TextStyle } from '@tiptap/extension-text-style'
import { Underline } from '@tiptap/extension-underline'
import { Typography } from '@tiptap/extension-typography'
import { Highlight } from '@tiptap/extension-highlight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBold,
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faIndent,
  faItalic,
  faList,
  faListOl,
  faOutdent,
  faPenRuler,
  faRedo,
  faTable,
  faUnderline,
  faUndo
} from '@fortawesome/free-solid-svg-icons'
import Modular from './modular.ts'
import { $editor } from '../state/chat.ts'
import {
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconColumnRemove,
  IconRowInsertBottom,
  IconRowInsertTop,
  IconRowRemove,
  IconTableOff
} from '@tabler/icons-react'

const $focused = atom(false)

const typographyOptions = {
  english: {
    emDash: '–'
  },
  french: {
    openSingleQuote: false,
    closeSingleQuote: false,
    openDoubleQuote: '« ',
    closeDoubleQuote: ' »'
  }
} as const

const frenchTypographyExtension = Extension.create({
  name: 'french-typography',
  addInputRules() {
    return [
      { find: /;$/, replace: ' ; ' },
      { find: /:$/, replace: ' : ' },
      { find: /\?$/, replace: ' ?' },
      { find: /!$/, replace: ' !' }
    ].map(textInputRule)
  }
})

const tableExtensions = [
  Table.configure({
    allowTableNodeSelection: true,
    lastColumnResizable: false
  }),
  TableRow,
  TableHeader,
  TableCell
]

const findParentRow = (nodes: any[]) => {
  return [...nodes].reverse().find((node) => node?.type?.name === 'tableRow')
}
const findParentTable = (nodes: any[]) => {
  return [...nodes].reverse().find((node) => node?.type?.name === 'table')
}

const findTableCell = (node: HTMLElement) => {
  while (node && !['TD', 'TH'].includes(node.tagName)) {
    node = node.parentElement!
  }

  return node
}

const findTableControl = (node: HTMLElement) => {
  while (node && !node.hasAttribute('data-control')) {
    node = node.parentElement!
  }

  return node
}

export const Editor = Modular.Component<{
  content: string
  onChange: (content: any, wordCount: number) => void
  forceSet?: boolean
  noMargin?: boolean
  shouldUpdate?: boolean
}>('Editor')
  .with(
    Modular.lifecycle(({ props, view }) => {
      const focused = useStore($focused)
      const defaultExtensions = [
        StarterKit,
        Typography.configure(
          typographyOptions[view.book.language!] ?? typographyOptions.english
        ),
        Underline,
        Highlight
      ]

      if (view.book.language === 'french') {
        defaultExtensions.push(frenchTypographyExtension)
      }

      const editor = useEditor({
        extensions: props.forceSet
          ? [...defaultExtensions, ...tableExtensions]
          : [...defaultExtensions, TextStyle, Color],
        content: props.content,
        onUpdate(p) {
          const value = p.editor.getJSON()
          const text = p.editor.getText({
            blockSeparator: ' '
          })

          if (JSON.stringify(value) !== JSON.stringify(props.content)) {
            props.onChange(value, text.split(' ').filter(Boolean).length)
          }
        },
        onFocus() {
          $focused.set(true)
        },
        onBlur() {
          $focused.set(false)
        },
        ...(props.forceSet
          ? {}
          : {
              onSelectionUpdate(props) {
                if (props.transaction.selection.empty) {
                  props.editor.commands.unsetColor()
                }
              },
              onTransaction(props) {
                _editor?.commands.setContent(props.editor.getJSON().content!)
                _editor
                  ?.chain()
                  .setTextSelection(props.editor.state.selection)
                  .setHighlight()
                  .run()

                if (
                  props.editor.isActive('textStyle') &&
                  props.transaction.steps?.length
                ) {
                  const step = props.transaction.steps[0].toJSON()
                  if (
                    'from' in step &&
                    'to' in step &&
                    step.from !== step.to &&
                    step.slice?.content.length === 1
                  ) {
                    props.editor
                      .chain()
                      .setTextSelection({ from: step.from, to: step.from + 1 })
                      .unsetColor()
                      .setTextSelection(step.from + 1)
                      .run()
                  }
                }
              }
            })
      })
      const _editor = useEditor({
        extensions: props.forceSet
          ? [...defaultExtensions, ...tableExtensions]
          : [...defaultExtensions, TextStyle, Color],
        content: props.content
      })
      const controls = useRef<HTMLDivElement>(null)

      useEffect(() => {
        const vv = window.visualViewport
        function fixPosition() {
          if (vv && controls.current) {
            controls.current.style.top = `${vv.height + vv.offsetTop + 1}px`
          }
        }
        vv?.addEventListener('scroll', fixPosition)
        vv?.addEventListener('resize', fixPosition)
        fixPosition()
        return () => vv?.removeEventListener('resize', fixPosition)
      }, [])

      useEffect(() => {
        if (!props.forceSet) {
          $editor.set(editor)
        }
      }, [editor, props.forceSet])

      useEffect(() => {
        if (props.shouldUpdate) {
          editor?.commands.setContent(props.content, false)
        }
      }, [editor, props.content, props.shouldUpdate])

      return { focused, editor, _editor, controls }
    })
  )
  .with(
    Modular.render(({ props, screen, lifecycle }) => (
      <div style={{ position: 'relative' }} className="compose-editor">
        <RichTextEditor
          px={{ base: '0.25rem', sm: props.forceSet ? '1rem' : '5rem' }}
          pt={
            props.noMargin
              ? {}
              : { base: '5rem', sm: props.forceSet ? '7rem' : '10rem' }
          }
          pb="4rem"
          mih={
            props.noMargin
              ? 'calc(100vh - 48px - 7rem - 2px)'
              : 'calc(100vh - 48px)'
          }
          editor={lifecycle.editor}
          className={
            (props.forceSet ? 'force-set ' : '') +
            (props.noMargin ? 'no-margin' : '')
          }
          onKeyDown={(e) => {
            if (!['Backspace', 'Delete'].includes(e.key)) return

            const editor = lifecycle.editor!

            if (
              '$anchorCell' in editor.state.selection &&
              editor.state.selection.ranges.length > 1
            ) {
              // Check if at least one full column or one full row is selected
              const table = findParentTable(
                (editor.state.selection.$anchor as any).path
              )
              const row = findParentRow(
                (editor.state.selection.$anchor as any).path
              )

              const nbRows = table.content.childCount as number
              const nbColumns = row.content.childCount as number

              const rows: Map<any, number> = editor.state.selection.ranges
                .map((range: any) => findParentRow(range.$from.path))
                .reduce((acc, curr) => {
                  if (!acc.has(curr)) {
                    acc.set(curr, 1)
                  } else {
                    acc.set(curr, acc.get(curr) + 1)
                  }
                  return acc
                }, new Map<any, number>())

              const allRows = rows.size === nbRows
              const allColumns = [...rows.values()].some(
                (count) => count === nbColumns
              )
              const includeHeaderRow = rows.has(table.content.firstChild)

              if (allRows) {
                editor.commands.deleteColumn()
              } else if (allColumns) {
                if (includeHeaderRow) {
                  editor.commands.deleteTable()
                } else {
                  editor.commands.deleteRow()
                }
              }
            }
          }}
          onMouseMove={
            screen.isDesktop
              ? (el) => {
                  const e = el.nativeEvent
                  const editor = lifecycle.editor!
                  const hoveredTd = findTableCell(e.target as HTMLElement)
                  const hoveredControl = findTableControl(
                    e.target as HTMLElement
                  )
                  const addRow = (e.currentTarget as HTMLElement).querySelector(
                    '[data-control=add-row]'
                  ) as HTMLElement
                  const addColumn = (
                    e.currentTarget as HTMLElement
                  ).querySelector('[data-control=add-column]') as HTMLElement

                  if (hoveredTd) {
                    const rect = hoveredTd.getBoundingClientRect()
                    const hoverLeft = e.x < rect.x + rect.width / 2
                    const hoverTop = e.y < rect.y + rect.height / 2

                    const rowOffset = hoverTop ? 0 : rect.height
                    const columnOffset = hoverLeft ? 0 : rect.width

                    const showRow =
                      (hoverTop
                        ? e.y < rect.y + 8
                        : e.y > rect.y + rect.height - 8) &&
                      e.x > rect.x + rect.width / 2 - 24 &&
                      e.x < rect.x + rect.width / 2 + 24 &&
                      hoveredTd.tagName === 'TD'
                    const showColumn = hoverLeft
                      ? e.x < rect.x + 8
                      : e.x > rect.x + rect.width - 8 &&
                        e.y > rect.y + rect.height / 2 - 24 &&
                        e.y < rect.y + rect.height / 2 + 24

                    const pos = editor.view.posAtDOM(hoveredTd, 0)

                    addRow.style.display = showRow ? 'flex' : 'none'
                    addRow.style.top = rect.top + rowOffset - 16 + 'px'
                    addRow.style.left = rect.left + rect.width / 2 - 16 + 'px'

                    addColumn.style.display = showColumn ? 'flex' : 'none'
                    addColumn.style.top = rect.top + rect.height / 2 - 16 + 'px'
                    addColumn.style.left = rect.left + columnOffset - 16 + 'px'

                    addRow.onclick = () => {
                      const rowAction = hoverTop
                        ? 'addRowBefore'
                        : 'addRowAfter'
                      editor.chain().focus(pos)[rowAction]().run()
                      addRow.style.display = 'none'
                    }
                    addColumn.onclick = () => {
                      const columnAction = hoverLeft
                        ? 'addColumnBefore'
                        : 'addColumnAfter'
                      editor.chain().focus(pos)[columnAction]().run()
                      addColumn.style.display = 'none'
                    }
                  } else if (![addRow, addColumn].includes(hoveredControl)) {
                    addRow.style.display = 'none'
                    addColumn.style.display = 'none'
                  }
                }
              : undefined
          }
        >
          {lifecycle.editor && !props.forceSet && screen.isDesktop && (
            <BubbleMenu editor={lifecycle.editor}>
              <RichTextEditor.ControlsGroup
                style={{
                  borderColor: 'var(--color-border)',
                  backgroundColor: 'var(--color-background)',
                  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.1)',
                  padding: '0.5rem'
                }}
              >
                <RichTextEditor.Control
                  data-color="draft"
                  onClick={() => lifecycle.editor?.commands.unsetColor()}
                  flex={1}
                >
                  <FontAwesomeIcon icon={faPenRuler} /> Draft
                </RichTextEditor.Control>
                <RichTextEditor.Control
                  data-color="revise"
                  onClick={() =>
                    lifecycle.editor?.commands.setColor('var(--color-revise)')
                  }
                  flex={1}
                >
                  <FontAwesomeIcon icon={faCircleXmark} /> Revise
                </RichTextEditor.Control>
                <RichTextEditor.Control
                  data-color="set"
                  onClick={() =>
                    lifecycle.editor?.commands.setColor('var(--color-set)')
                  }
                  flex={1}
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Set
                </RichTextEditor.Control>
              </RichTextEditor.ControlsGroup>
            </BubbleMenu>
          )}
          {lifecycle.editor && props.forceSet && screen.isDesktop && (
            <FloatingMenu editor={lifecycle.editor}>
              <RichTextEditor.ControlsGroup
                style={{
                  border: 0,
                  backgroundColor: 'var(--color-columns)',
                  padding: 0
                }}
              >
                <RichTextEditor.Control>
                  <FontAwesomeIcon
                    icon={faList}
                    onClick={() =>
                      lifecycle.editor?.commands.toggleBulletList()
                    }
                  />
                </RichTextEditor.Control>
                <RichTextEditor.Control>
                  <FontAwesomeIcon
                    icon={faListOl}
                    onClick={() =>
                      lifecycle.editor?.commands.toggleOrderedList()
                    }
                  />
                </RichTextEditor.Control>
                <RichTextEditor.Control>
                  <FontAwesomeIcon
                    icon={faTable}
                    onClick={() =>
                      lifecycle.editor?.commands.insertTable({
                        rows: 2,
                        cols: 2,
                        withHeaderRow: true
                      })
                    }
                  />
                </RichTextEditor.Control>
              </RichTextEditor.ControlsGroup>
            </FloatingMenu>
          )}
          <RichTextEditor.Control data-control="add-row">
            <FontAwesomeIcon icon={faCirclePlus} />
          </RichTextEditor.Control>
          <RichTextEditor.Control data-control="add-column">
            <FontAwesomeIcon icon={faCirclePlus} />
          </RichTextEditor.Control>
          <RichTextEditor.Content />
          {!screen.isDesktop && !props.forceSet && (
            <RichTextEditor.ControlsGroup
              pos="fixed"
              left={-1}
              right={-1}
              // bottom={-1}
              ref={lifecycle.controls}
              style={{
                display: lifecycle.focused ? 'flex' : 'none',
                borderWidth: '0.5px',
                borderColor: 'var(--color-tools-border)',
                backgroundColor: 'var(--color-tools)',
                borderRadius: 0,
                justifyContent: 'center',
                transform: 'translateY(-100%)',
                position: 'relative',
                zIndex: 10
              }}
            >
              <RichTextEditor.Control
                data-color="draft"
                onClick={() => lifecycle.editor?.commands.unsetColor()}
                flex={1}
              >
                <FontAwesomeIcon icon={faPenRuler} /> Draft
              </RichTextEditor.Control>
              <RichTextEditor.Control
                data-color="revise"
                onClick={() =>
                  lifecycle.editor?.commands.setColor('var(--color-revise)')
                }
                flex={1}
              >
                <FontAwesomeIcon icon={faCircleXmark} /> Revise
              </RichTextEditor.Control>
              <RichTextEditor.Control
                data-color="set"
                onClick={() =>
                  lifecycle.editor?.commands.setColor('var(--color-set)')
                }
                flex={1}
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Set
              </RichTextEditor.Control>
            </RichTextEditor.ControlsGroup>
          )}
          {!screen.isDesktop && props.forceSet && (
            <RichTextEditor.ControlsGroup
              pos="fixed"
              left={-1}
              right={-1}
              // bottom={-1}
              ref={lifecycle.controls}
              style={{
                display: lifecycle.focused ? 'flex' : 'none',
                borderWidth: '0.5px',
                borderColor: 'var(--color-tools-border)',
                backgroundColor: 'var(--color-tools)',
                borderRadius: 0,
                justifyContent: 'center',
                transform: 'translateY(-100%)',
                flexWrap: 'wrap'
              }}
            >
              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faBold}
                  onClick={() => lifecycle.editor?.commands.toggleBold()}
                />
              </RichTextEditor.Control>
              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faItalic}
                  onClick={() => lifecycle.editor?.commands.toggleItalic()}
                />
              </RichTextEditor.Control>
              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faUnderline}
                  onClick={() => lifecycle.editor?.commands.toggleUnderline()}
                />
              </RichTextEditor.Control>

              <div className="menu-separator" />

              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faList}
                  onClick={() => lifecycle.editor?.commands.toggleBulletList()}
                />
              </RichTextEditor.Control>
              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faListOl}
                  onClick={() => lifecycle.editor?.commands.toggleOrderedList()}
                />
              </RichTextEditor.Control>
              <RichTextEditor.Control flex={1}>
                <FontAwesomeIcon
                  icon={faTable}
                  onClick={() =>
                    lifecycle.editor?.commands.insertTable({
                      rows: 2,
                      cols: 2,
                      withHeaderRow: true
                    })
                  }
                />
              </RichTextEditor.Control>

              {(lifecycle.editor?.isActive('bulletList') ||
                lifecycle.editor?.isActive('orderedList')) && (
                <>
                  <div className="menu-separator" />

                  <RichTextEditor.Control
                    flex={1}
                    disabled={!lifecycle.editor?.can().sinkListItem('listItem')}
                  >
                    <FontAwesomeIcon
                      icon={faIndent}
                      onClick={() =>
                        lifecycle.editor?.commands.sinkListItem('listItem')
                      }
                    />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    disabled={!lifecycle.editor?.can().liftListItem('listItem')}
                  >
                    <FontAwesomeIcon
                      icon={faOutdent}
                      onClick={() =>
                        lifecycle.editor?.commands.liftListItem('listItem')
                      }
                    />
                  </RichTextEditor.Control>
                </>
              )}

              <div className="menu-separator" />

              <RichTextEditor.Control
                flex={1}
                disabled={!lifecycle.editor?.can().undo()}
              >
                <FontAwesomeIcon
                  icon={faUndo}
                  onClick={() => lifecycle.editor?.commands.undo()}
                />
              </RichTextEditor.Control>
              <RichTextEditor.Control
                flex={1}
                disabled={!lifecycle.editor?.can().redo()}
              >
                <FontAwesomeIcon
                  icon={faRedo}
                  onClick={() => lifecycle.editor?.commands.redo()}
                />
              </RichTextEditor.Control>

              {lifecycle.editor?.isActive('table') && (
                <UI.Group gap={0} flex="none" wrap="nowrap" w="100%">
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.addColumnAfter()}
                  >
                    <IconColumnInsertLeft />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.addColumnBefore()}
                  >
                    <IconColumnInsertRight />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.addRowAfter()}
                  >
                    <IconRowInsertTop />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.addRowBefore()}
                    disabled={lifecycle.editor?.isActive('tableHeader')}
                  >
                    <IconRowInsertBottom />
                  </RichTextEditor.Control>
                  <div className="menu-separator" />
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.deleteColumn()}
                  >
                    <IconColumnRemove />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.deleteRow()}
                    disabled={lifecycle.editor?.isActive('tableHeader')}
                  >
                    <IconRowRemove />
                  </RichTextEditor.Control>
                  <RichTextEditor.Control
                    flex={1}
                    onClick={() => lifecycle.editor?.commands.deleteTable()}
                  >
                    <IconTableOff />
                  </RichTextEditor.Control>
                </UI.Group>
              )}
            </RichTextEditor.ControlsGroup>
          )}
        </RichTextEditor>

        {!props.forceSet && (
          <RichTextEditor
            px={{ base: '0.25rem', sm: '5rem' }}
            pt={props.noMargin ? {} : { base: '5rem', sm: '10rem' }}
            pb="4rem"
            mih={
              props.noMargin
                ? 'calc(100vh - 48px - 7rem - 2px)'
                : 'calc(100vh - 48px)'
            }
            editor={lifecycle._editor}
            className={(props.noMargin ? 'no-margin ' : '') + 'ghost-editor'}
            style={{
              position: 'absolute',
              top: 0
            }}
          >
            <RichTextEditor.Content />
          </RichTextEditor>
        )}
      </div>
    ))
  )
